import React, { useState, useEffect, useRef } from 'react';
import { CheckCircle, XCircle, AlertCircle, Info, X } from 'lucide-react';
import { ToastVariant, VariantConfig } from './types';

interface ToastProps {
  message: string;
  duration?: number;
  show?: boolean;
  onClose?: () => void;
  variant?: ToastVariant;
  customTitle?: string;
}

const VARIANTS: Record<ToastVariant, VariantConfig> = {
  success: {
    bg: 'bg-green-50',
    progressBg: 'bg-green-500',
    icon: CheckCircle,
    iconColor: 'text-green-500',
    title: 'Success'
  },
  error: {
    bg: 'bg-red-50',
    progressBg: 'bg-red-500',
    icon: XCircle,
    iconColor: 'text-red-500',
    title: 'Error'
  },
  warning: {
    bg: 'bg-yellow-50',
    progressBg: 'bg-yellow-500',
    icon: AlertCircle,
    iconColor: 'text-yellow-500',
    title: 'Warning'
  },
  info: {
    bg: 'bg-blue-50',
    progressBg: 'bg-blue-500',
    icon: Info,
    iconColor: 'text-blue-500',
    title: 'Information'
  }
};

export const Toast: React.FC<ToastProps> = ({
  message = "This is a notification",
  duration = 5000,
  show = false,
  onClose,
  variant = 'info',
  customTitle
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(show);
  const [animation, setAnimation] = useState<string>('translate-y-full opacity-0');
  const [progress, setProgress] = useState<number>(100);
  const progressInterval = useRef<NodeJS.Timeout | null>(null);
  const variantConfig = VARIANTS[variant];
  const Icon = variantConfig.icon;

  useEffect(() => {
    if (show) {
      setIsVisible(true);
      setAnimation('translate-y-0 opacity-100');
      setProgress(100);

      const startTime = Date.now();
      const updateProgress = () => {
        const elapsed = Date.now() - startTime;
        const remaining = Math.max(0, 100 * (1 - elapsed / duration));
        setProgress(remaining);

        if (remaining <= 0) {
          handleClose();
        }
      };

      progressInterval.current = setInterval(updateProgress, 10);
    }

    return () => {
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
      }
    };
  }, [show, duration]);

  const handleClose = (): void => {
    if (progressInterval.current) {
      clearInterval(progressInterval.current);
    }
    setAnimation('translate-y-full opacity-0');
    setTimeout(() => {
      setIsVisible(false);
      onClose?.();
    }, 300);
  };

  if (!isVisible) return null;

  return (
    <div
      onClick={handleClose}
      className={`
        relative cursor-pointer
        ${variantConfig.bg}
        rounded-lg shadow-lg
        w-full md:w-auto md:min-w-[320px] md:max-w-md
        transform transition-all duration-300 ease-in-out
        ${animation}
        overflow-hidden
      `}
    >
      <div
        className={`absolute top-0 left-0 h-1 transition-all duration-100 ${variantConfig.progressBg}`}
        style={{ width: `${progress}%` }}
      />

      <div className="p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <Icon className={`w-5 h-5 ${variantConfig.iconColor}`} />
            <h4 className={`font-semibold ${variantConfig.iconColor}`}>
              {customTitle || variantConfig.title}
            </h4>
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
            }}
            className="text-gray-400 hover:text-gray-600 transition-colors"
          >
            <X className="w-4 h-4" />
          </button>
        </div>

        <p className="mt-2 text-gray-700">
          {message}
        </p>
      </div>
    </div>
  );
};