import ContactList from '@/components/contacts';
import useContactStore from '@/components/contacts/store';
import ContactUI from '@/components/contacts/view';
import { ArrowLeft } from 'lucide-react';
import React from 'react';

const Contacts: React.FC = () => {
    const selectedContact = useContactStore(state => state.selectedContact);
    const setSelectedContact = useContactStore(state => state.setSelectedContact);


    return (
        <div className="w-screen">
            {selectedContact ?
                <>
                    {/* Back button - only visible on mobile when contact selected */}
                    <button
                        className="absolute top-4 left-4 z-20 p-2 rounded-full hover:bg-gray-100 md:hidden"
                        onClick={() => setSelectedContact(null)}
                    >
                        <ArrowLeft className="h-6 w-6" />
                    </button>
                    <ContactUI />
                </>
                : <ContactList />}
        </div>
    );
};

export default Contacts;