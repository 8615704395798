import { ArrowRight, Loader2, Mail } from 'lucide-react';
import React, { useState, ChangeEvent } from 'react';
import useAuthStore from './store';

interface SignInPageProps {
    onError: (message: string) => void;
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const SignInPage: React.FC<SignInPageProps> = ({
    onError
}) => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const isLoading = useAuthStore(state => state.isLoading);

    const signInWithGoogle = useAuthStore(state => state.signInWithGoogle);
    const signInWithEmail = useAuthStore(state => state.signInWithEmail);

    const handleSubmit = async () => {
        setEmailError('');

        if (!email || !emailRegex.test(email)) {
            setEmailError('Please enter a valid email address');
            return;
        }

        try {
            await signInWithEmail(email);
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'Something went wrong. Please try again.';
            setEmailError(errorMessage);
            onError(errorMessage);
        }
    };

    return (
        <div className="bg-white rounded-xl shadow-lg p-8 w-full max-w-md">
            <div className="space-y-10">
                <div className="space-y-2">
                    <h1 className="text-2xl font-semibold">Welcome</h1>
                    <p className="text-sm text-gray-500">Sign in with your email or Google account</p>
                </div>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="space-y-1">
                        <div className="relative">
                            <Mail className="absolute left-3 top-3.5 h-4 w-4 text-gray-400" />
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setEmail(e.target.value);
                                    setEmailError('');
                                }}
                                className={`w-full h-11 pl-10 pr-4 rounded-lg border 
                                    ${emailError ? 'border-red-300' : 'border-gray-200'}
                                    focus:outline-none focus:ring-2 
                                    ${emailError ? 'focus:ring-red-500' : 'focus:ring-purple-500'}
                                    focus:border-transparent`}
                                disabled={isLoading}
                            />
                        </div>
                        {emailError && <p className="text-sm text-red-500">{emailError}</p>}
                    </div>

                    <button
                        type="submit"
                        className={`w-full h-11 rounded-lg font-medium transition-all flex items-center justify-center gap-2
                        bg-zinc-900 hover:bg-black text-white 
                        ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <Loader2 className="animate-spin h-4 w-4" />
                        ) : (
                            <>Continue with Email <ArrowRight className="h-4 w-4" /></>
                        )}
                    </button>
                </form>

                <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                        <span className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex justify-center text-xs uppercase">
                        <span className="bg-white px-4 text-gray-500">or continue with</span>
                    </div>
                </div>

                <button
                    onClick={signInWithGoogle}
                    type="button"
                    className="w-full h-11 rounded-lg font-medium border border-gray-200 hover:bg-gray-50 
                        text-gray-700 flex items-center justify-center gap-2 transition-colors"
                    disabled={isLoading}
                >
                    <svg className="h-5 w-5" viewBox="0 0 24 24">
                        <path
                            fill="#4285F4"
                            d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                        />
                        <path
                            fill="#34A853"
                            d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                        />
                        <path
                            fill="#FBBC05"
                            d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                        />
                        <path
                            fill="#EA4335"
                            d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                        />
                    </svg>
                    Continue with Google
                </button>
            </div>
        </div>
    );
};

export default SignInPage;