// src/lib/store/tags.store.ts

import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../config/fconfig';
import { create } from 'zustand';
import useAuthStore from '@/components/auth/store';

export interface ITag {
  id: string;
  label: string;
  createdAt: Date;
  updatedAt: Date;
}

interface TagsState {
  tags: ITag[];
  loading: boolean;
  error: string | null;
  selectedTags: ITag[];

  // Actions
  fetchTags: () => Promise<void>;
  createTag: (label: string) => Promise<ITag>;
  deleteTag: (tagId: string) => Promise<void>;
  updateTag: (tagId: string, label: string) => Promise<void>;
  selectTag: (tag: ITag) => void;
  unselectTag: (tagId: string) => void;
  setSelectedTags: (tags: ITag[]) => void;
  reset: () => void;
}

const isValidTag = (tag: any): tag is ITag => {
  return (
    tag &&
    typeof tag.id === 'string' &&
    typeof tag.label === 'string'
  );
};

const safeToDate = (dateValue: any): Date => {
  if (dateValue instanceof Date) return dateValue;
  if (dateValue && typeof dateValue.toDate === 'function') {
    try {
      return dateValue.toDate();
    } catch (e) {
      return new Date();
    }
  }
  return new Date();
};

export const useTagsStore = create<TagsState>()((set, get) => ({
  tags: [],
  loading: false,
  error: null,
  selectedTags: [],

  fetchTags: async () => {


    try {
      set({ loading: true, error: null });

      const tagsRef = collection(db, `ORG/${useAuthStore.getState().getOrgId()}/TAG`);
      const querySnapshot = await getDocs(tagsRef);

      const tags: ITag[] = querySnapshot.docs
        .map(doc => {
          const data = doc.data();
          if (!data) return null;

          const tag = {
            id: doc.id,
            label: data.label || '',
            createdAt: safeToDate(data.createdAt),
            updatedAt: safeToDate(data.updatedAt),
          };

          return isValidTag(tag) ? tag : null;
        })
        .filter((tag): tag is ITag => tag !== null);

      set({ tags, loading: false });
    } catch (error) {
      set({
        error: error instanceof Error ? error.message : 'Failed to fetch tags',
        loading: false
      });
    }
  },

  createTag: async (label: string) => {
    if (!label?.trim()) {
      throw new Error('Tag label is required');
    }

    try {
      set({ loading: true, error: null });

      const normalizedLabel = label.trim();

      // Check if tag already exists
      const existingTag = get().tags.find(
        tag => tag?.label.toLowerCase() === normalizedLabel.toLowerCase()
      );

      if (existingTag) {
        throw new Error('Tag already exists');
      }

      const now = new Date();
      const newTag = {
        label: normalizedLabel,
        createdAt: now,
        updatedAt: now,
      };

      const docRef = await addDoc(collection(db, `ORG/${useAuthStore.getState().getOrgId()}/TAG`), newTag);
      if (!docRef?.id) {
        throw new Error('Failed to create tag: Invalid document reference');
      }

      const createdTag = { ...newTag, id: docRef.id };

      if (!isValidTag(createdTag)) {
        throw new Error('Failed to create tag: Invalid tag data');
      }

      set(state => ({
        tags: [...(state.tags || []), createdTag],
        loading: false
      }));


      return createdTag;
    } catch (error) {
      set({
        error: error instanceof Error ? error.message : 'Failed to create tag',
        loading: false
      });
      throw error;
    }
  },

  deleteTag: async (tagId: string) => {
    if (!tagId) {
      throw new Error('Tag ID is required');
    }

    try {
      set({ loading: true, error: null });

      await deleteDoc(doc(db, `ORG/${useAuthStore.getState().getOrgId()}/TAG`, tagId));

      set(state => ({
        tags: (state.tags || []).filter(tag => tag?.id !== tagId),
        selectedTags: (state.selectedTags || []).filter(tag => tag?.id !== tagId),
        loading: false
      }));
    } catch (error) {
      set({
        error: error instanceof Error ? error.message : 'Failed to delete tag',
        loading: false
      });
      throw error;
    }
  },

  updateTag: async (tagId: string, label: string) => {
    if (!tagId) {
      throw new Error('Tag ID is required');
    }

    if (!label?.trim()) {
      throw new Error('Tag label is required');
    }

    try {
      set({ loading: true, error: null });

      const normalizedLabel = label.trim();
      const tagRef = doc(db, `ORG/${useAuthStore.getState().getOrgId()}/TAG`, tagId);

      const updateData = {
        label: normalizedLabel,
        updatedAt: new Date()
      };

      await updateDoc(tagRef, updateData);

      set(state => ({
        tags: (state.tags || []).map(tag =>
          tag?.id === tagId
            ? { ...tag, ...updateData }
            : tag
        ).filter(isValidTag),
        selectedTags: (state.selectedTags || []).map(tag =>
          tag?.id === tagId
            ? { ...tag, ...updateData }
            : tag
        ).filter(isValidTag),
        loading: false
      }));
    } catch (error) {
      set({
        error: error instanceof Error ? error.message : 'Failed to update tag',
        loading: false
      });
      throw error;
    }
  },

  selectTag: (tag: ITag) => {
    if (!isValidTag(tag)) {
      return;
    }

    set(state => ({
      selectedTags: (state.selectedTags || []).some(t => t?.id === tag.id)
        ? state.selectedTags
        : [...(state.selectedTags || []), tag]
    }));
  },

  unselectTag: (tagId: string) => {
    if (!tagId) {
      return;
    }

    set(state => ({
      selectedTags: (state.selectedTags || []).filter(tag => tag?.id !== tagId)
    }));
  },

  setSelectedTags: (tags: ITag[]) => {
    set({
      selectedTags: Array.isArray(tags)
        ? tags.filter(isValidTag)
        : []
    });
  },

  reset: () => {
    set({
      tags: [],
      selectedTags: [],
      loading: false,
      error: null
    });
  },
}));