/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Search, Clock, Loader2, Users } from 'lucide-react';
import { useDebounce } from 'use-debounce';
import useContactStore, { IContactDetails } from './store';
import { Timestamp } from 'firebase/firestore';
import ContactAvatar from '../blocks/avatar';
import PhoneDialer from '../dialer';

const SEARCH_DELAY = 300; // ms

interface ContactItemProps {
    contact: IContactDetails;
}

const ContactList: React.FC = () => {
    const {
        allContacts,
        isLoading,
        error,
        fetchAllContacts,
        startRealtimeUpdates,
        stopRealtimeUpdates,
        selectedContact,
        setSelectedContact,
    } = useContactStore();

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [debouncedSearch] = useDebounce(searchQuery, SEARCH_DELAY);
    const [isSearchVisible, setIsSearchVisible] = useState(false);

    useEffect(() => {
        startRealtimeUpdates();
        fetchAllContacts();

        return () => {
            stopRealtimeUpdates();
        };
    }, []);

    const handleContactClick = async (contactId: string) => {
        if (selectedContact?.id === contactId) {
            setSelectedContact(null);
        } else {
            setSelectedContact(contactId);
        }
    };

    const formatDateTime = (dateTime: Date | Timestamp): string => {
        if (dateTime instanceof Timestamp) {
            dateTime = dateTime.toDate();
        }
        const now = new Date();
        const date = new Date(dateTime);
        const diffInHours = (now.getTime() - date.getTime()) / (1000 * 60 * 60);

        if (diffInHours < 24) {
            return date.toLocaleString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            });
        } else if (diffInHours < 168) {
            return date.toLocaleString('en-US', {
                weekday: 'short'
            });
        }

        return date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric'
        });
    };

    const formatDuration = (duration: number): string => {
        const minutes = Math.floor(duration / 60);
        const seconds = duration % 60;
        return `${minutes}m ${seconds}s`;
    };

    const ContactItem: React.FC<ContactItemProps> = React.memo(({ contact }) => (
        <div
            onClick={() => handleContactClick(contact.id)}
            className={`
                md:flex md:items-center md:justify-between md:p-4 md:border-b md:border-gray-100 
                
                /* Mobile styles */
                mx-4 my-3 p-4 rounded-2xl bg-white shadow-sm border border-gray-100
                ${selectedContact?.id === contact.id ? 'bg-blue-50' : ''}
                md:mx-0 md:my-0 md:rounded-none md:shadow-none
            `}
        >
            {/* Mobile Layout */}
            <div className="md:hidden flex flex-col items-center pt-12 pb-12">
                {/* Avatar centered at top */}
                <div className="mb-3">
                    <ContactAvatar input={contact.name || contact.phone} size="md" />
                </div>

                {/* Content below avatar */}
                <div className="text-center w-full">
                    <h3 className="font-medium text-gray-900">
                        {contact.name || contact.phone || 'Unknown Contact'}
                    </h3>
                    <div className="flex flex-col items-center gap-1 mt-1">
                        {contact.lastActivity?.startTime && (
                            <p className="text-sm text-gray-500">
                                Last call about {formatDateTime(contact.lastActivity.startTime)}
                            </p>
                        )}
                        <p className="text-sm text-gray-500">
                            Ongoing Call
                        </p>
                        {contact.lastActivity?.duration && (
                            <span className="inline-flex items-center bg-gray-100 rounded-full px-2 py-0.5 text-sm text-gray-500">
                                <Clock className="w-3 h-3 mr-1" />
                                {formatDuration(contact.lastActivity.duration)}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            {/* Desktop Layout */}
            <div className="hidden md:flex md:items-center md:space-x-3">
                <ContactAvatar input={contact.name || contact.phone} size="sm" />
                <div className="flex-1 min-w-0">
                    <p className="font-medium text-gray-900 truncate">
                        {contact.name || contact.phone || 'Unknown Contact'}
                    </p>
                    <div className="flex flex-wrap items-center gap-2 mt-1 text-sm text-gray-500">
                        {contact.lastActivity?.duration && (
                            <span className="inline-flex items-center bg-gray-100 rounded-full px-2 py-0.5">
                                <Clock className="w-3 h-3 mr-1" />
                                {formatDuration(contact.lastActivity.duration)}
                            </span>
                        )}
                        {contact.lastActivity?.startTime && (
                            <span className="text-xs text-gray-400">
                                {formatDateTime(contact.lastActivity.startTime)}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ));

    const EmptyState: React.FC = () => (
        <div className="flex flex-col items-center justify-center p-8">
            <div className="bg-gray-100 rounded-full p-4 mb-4">
                <Users className="w-8 h-8 text-gray-400" />
            </div>
            <h3 className="text-base font-medium text-gray-900 mb-1">
                No contacts found
            </h3>
            <p className="text-sm text-gray-500 text-center">
                Try adjusting your search
            </p>
        </div>
    );

    const filterContacts = (): IContactDetails[] => {
        if (!debouncedSearch) return allContacts;

        return allContacts.filter(contact =>
            contact.name?.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
            contact.email?.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
            contact.phone?.includes(debouncedSearch)
        );
    };

    const filteredContacts = filterContacts();

    return (
        <div className="h-full flex flex-col bg-gray-50 md:bg-white">
            <div className="safe-top flex-none px-4 pt-4">
                <div className="flex items-center justify-between mb-3">
                    <div>
                        <h2 className="text-lg font-semibold text-gray-900">Contacts</h2>
                        <p className="text-xs text-gray-500">
                            {filteredContacts.length} {filteredContacts.length === 1 ? 'contact' : 'contacts'}
                        </p>
                    </div>
                    <button
                        onClick={() => setIsSearchVisible(!isSearchVisible)}
                        className="p-2 rounded-full hover:bg-gray-100 active:bg-gray-200"
                    >
                        <Search className="w-5 h-5 text-gray-500" />
                    </button>
                </div>

                {isSearchVisible && (
                    <div className="pb-3">
                        <input
                            type="search"
                            className="w-full px-4 py-2 rounded-full bg-gray-100 text-sm focus:outline-none 
                                     focus:ring-2 focus:ring-blue-500 focus:bg-white border border-transparent
                                     focus:border-blue-500 transition-colors"
                            placeholder="Search contacts..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                )}
            </div>

            <div className="flex-1 overflow-y-auto no-scrollbar ">
                <div className='md:hidden mb-6 ml-4 mr-4 mt-4' > <PhoneDialer /> </div>
                {error && (
                    <div className="m-4 p-3 bg-red-50 text-red-600 text-sm rounded-lg flex items-center">
                        <span className="mr-2">⚠️</span>
                        {error}
                    </div>
                )}

                {isLoading && filteredContacts.length === 0 ? (
                    <div className="h-full flex items-center justify-center">
                        <Loader2 className="w-6 h-6 text-gray-400 animate-spin" />
                    </div>
                ) : filteredContacts.length === 0 ? (
                    <EmptyState />
                ) : (
                    <div className="divide-y divide-gray-100 no-scrollbar">

                        {filteredContacts.map((contact) => (
                            <ContactItem key={contact.id} contact={contact} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ContactList;