// Utility functions for base64 encoding/decoding of array buffers
const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
};

const base64ToArrayBuffer = (base64: string): ArrayBuffer => {
    const binary = atob(base64);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
        bytes[i] = binary.charCodeAt(i);
    }
    return bytes.buffer;
};

// Encryption utilities
const generateEncryptionKey = async (): Promise<CryptoKey> => {
    const key = await window.crypto.subtle.generateKey(
        {
            name: 'AES-GCM',
            length: 256
        },
        true,
        ['encrypt', 'decrypt']
    );
    return key;
};

const getEncryptionKey = async (): Promise<CryptoKey> => {
    const storedKey = localStorage.getItem('auth-encryption-key');
    if (storedKey) {
        const keyBuffer = base64ToArrayBuffer(storedKey);
        return await window.crypto.subtle.importKey(
            'raw',
            keyBuffer,
            'AES-GCM',
            true,
            ['encrypt', 'decrypt']
        );
    }
    const newKey = await generateEncryptionKey();
    const exportedKey = await window.crypto.subtle.exportKey('raw', newKey);
    localStorage.setItem('auth-encryption-key', arrayBufferToBase64(exportedKey));
    return newKey;
};

const encrypt = async (data: string): Promise<string> => {
    const key = await getEncryptionKey();
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const encoded = new TextEncoder().encode(data);

    const ciphertext = await window.crypto.subtle.encrypt(
        {
            name: 'AES-GCM',
            iv
        },
        key,
        encoded
    );

    const encryptedData = {
        iv: Array.from(iv),
        ciphertext: arrayBufferToBase64(ciphertext)
    };

    return JSON.stringify(encryptedData);
};

const decrypt = async (encryptedData: string): Promise<string> => {
    const key = await getEncryptionKey();
    const { iv, ciphertext } = JSON.parse(encryptedData);

    const decrypted = await window.crypto.subtle.decrypt(
        {
            name: 'AES-GCM',
            iv: new Uint8Array(iv)
        },
        key,
        base64ToArrayBuffer(ciphertext)
    );

    return new TextDecoder().decode(decrypted);
};

export { encrypt, decrypt, generateEncryptionKey, getEncryptionKey, arrayBufferToBase64, base64ToArrayBuffer };