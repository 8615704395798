import React from 'react';
import { HelpCircle } from 'lucide-react';

interface FormLabelProps {
  htmlFor: string;
  label?: string;
  description?: string;
}

const FormLabel: React.FC<FormLabelProps> = ({ htmlFor, label, description }) => (
  <>
    {label && label.toString().trim() !== '' && (
      <div className="flex items-center gap-2 mb-2">
        <label
          htmlFor={htmlFor}
          className="text-sm font-semibold text-gray-600 dark:text-gray-600"
        >
          {label}
        </label>
        
        {description && (
          <span title={description}>
            <HelpCircle className="h-4 w-4 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 cursor-help" />
          </span>
        )}
      </div>
    )}
  </>
);

export default FormLabel;