import { create } from 'zustand';
import { persist, StorageValue, PersistStorage } from 'zustand/middleware';
import {
    User,
    signOut,
    onAuthStateChanged,
    signInWithCustomToken,
    // signInWithRedirect,
    // getRedirectResult,
    signInWithPopup
} from 'firebase/auth';
import { auth, db, functions, googleProvider, storage } from '../../../config/fconfig';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { httpsCallable } from 'firebase/functions';
import { doc, getDoc, updateDoc, collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { decrypt, encrypt } from './crypt';
import { useToastStore } from '@/components/toast/store';
import useCredentialsStore from '@/components/credentials/store';

// Add new interfaces for membership
interface Membership {
    orgId: string;
    createdAt: string;
    status: string;
}

export interface UserData {
    uid: string;
    email: string | null;
    displayName: string | null;
    photoURL: string | null;
    photoStoragePath?: string;
    isNewUser: boolean;
    createdAt: string | null;
    phoneNumber?: string;
    customFields?: Record<string, any>;
    communicationPreferences?: Record<string, any>;
    activeMembership?: Membership | null; // Add active membership to user data
}

export interface EmailData {
    email: string;
}

export interface UpdateUserData {
    displayName?: string;
    phoneNumber?: string;
    [key: string]: any;
}

interface EmailVerificationResponse {
    success: boolean;
    message: string;
    sessionId?: string;
}

interface VerifyOTPResponse {
    success: boolean;
    message: string;
    token?: string;
}

interface ImageUploadResult {
    downloadURL: string;
    storagePath: string;
}

export interface AuthState {
    user: UserData | null;
    email: string | null;
    isAuthenticated: boolean;
    isLoading: boolean;
    error: string | null;
    sessionId: string | null;
    activeMembership: Membership | null;
    signInWithGoogle: () => Promise<User>;
    signInWithEmail: (email: string) => Promise<EmailVerificationResponse>;
    verifyOTP: (otp: string) => Promise<VerifyOTPResponse>;
    signOut: () => Promise<void>;
    clearError: () => void;
    init: () => void;
    updateUserDetails: (data: UpdateUserData) => Promise<void>;
    uploadProfileImage: (file: File) => Promise<ImageUploadResult>;
    getOrgId: () => string;
}

const USER = 'USER';
const MEMBERSHIP = 'MEMBERSHIP';
const PROFILE_IMAGES = 'profile-images';

// Custom storage with proper types for Zustand
const createEncryptedStorage = (): PersistStorage<{ user: UserData | null; isAuthenticated: boolean; activeMembership: Membership | null }> => ({
    getItem: async (name: string): Promise<StorageValue<{ user: UserData | null; isAuthenticated: boolean; activeMembership: Membership | null }> | null> => {
        const encrypted = localStorage.getItem(name);
        if (!encrypted) return null;
        try {
            const decrypted = await decrypt(encrypted);
            return JSON.parse(decrypted) as StorageValue<AuthState>;
        } catch (error) {
            console.error('Failed to decrypt storage:', error);
            return null;
        }
    },
    setItem: async (name: string, value: StorageValue<{ user: UserData | null; isAuthenticated: boolean; activeMembership: Membership | null }>): Promise<void> => {
        try {
            const encrypted = await encrypt(JSON.stringify(value));
            localStorage.setItem(name, encrypted);
        } catch (error) {
            console.error('Failed to encrypt storage:', error);
        }
    },
    removeItem: (name: string): void => {
        localStorage.removeItem(name);
    }
});

const mapUserData = (user: User, firestoreData?: Record<string, any>, metadata?: { isNewUser?: boolean }): UserData => ({
    uid: user.uid,
    email: user.email,
    displayName: user.displayName,
    photoURL: user.photoURL,
    isNewUser: metadata?.isNewUser ?? false,
    createdAt: user.metadata.creationTime ?? null,
    ...firestoreData
});

const fetchUserDetailsAndMembership = async (user: User): Promise<{ userData: UserData; membership: Membership | null }> => {
    const userDoc = await getDoc(doc(db, USER, user.uid));
    let userData: UserData;

    if (userDoc.exists()) {
        userData = mapUserData(user, userDoc.data());
    } else {
        userData = mapUserData(user);
    }

    // Query memberships collection
    const membershipsRef = collection(db, `${USER}/${user.uid}/${MEMBERSHIP}`);
    const membershipsQuery = query(membershipsRef, orderBy('createdAt', 'asc'), limit(2));
    const membershipsSnapshot = await getDocs(membershipsQuery);

    let membership: Membership | null = null;

    if (!membershipsSnapshot.empty) {
        // If only one membership or first membership by creation date
        const membershipDoc = membershipsSnapshot.docs[0];
        membership = {
            orgId: membershipDoc.id,
            createdAt: membershipDoc.data().createdAt,
            status: membershipDoc.data().status
        };
    }

    useCredentialsStore.getState().loadFromFirestore();
    return { userData, membership };
};

const useAuthStore = create<AuthState>()(
    persist(
        (set, get) => ({
            user: null,
            isAuthenticated: false,
            isLoading: true,
            error: null,
            sessionId: null,
            email: null,
            activeMembership: null,

            init: () => {
                const unsubscribe = onAuthStateChanged(auth, async (user) => {
                    if (user) {
                        try {
                            const { userData, membership } = await fetchUserDetailsAndMembership(user);

                            set({
                                user: {
                                    ...userData,
                                    communicationPreferences: {
                                        callNotifications: false,
                                        marketing: true,
                                        productNews: true,
                                        serviceUpdates: true,
                                    }
                                },
                                activeMembership: membership,
                                isAuthenticated: true,
                                isLoading: false,
                            });
                        } catch (error) {
                            console.error('Error fetching user data:', error);
                            set({
                                user: mapUserData(user),
                                isAuthenticated: true,
                                isLoading: false,
                                error: 'Failed to fetch user details'
                            });
                        }
                    } else {
                        set({
                            user: null,
                            activeMembership: null,
                            isAuthenticated: false,
                            isLoading: false
                        });
                    }
                });
                return () => unsubscribe();
            },

            signInWithGoogle: async () => {
                try {
                    set({ isLoading: true, error: null });
                    const result = await signInWithPopup(auth, googleProvider);
                    // const result = await getRedirectResult(auth);
                    // if (!result) {
                    // useToastStore.getState().addToast({
                    //     message: 'An unknown error occurred: Redirect result not found. Please try again.',
                    //     variant: 'error',
                    // });
                    //     throw new Error('An unknown error occurred: Redirect result not found');
                    // }

                    const { userData, membership } = await fetchUserDetailsAndMembership(result.user);

                    set({
                        user: userData,
                        activeMembership: membership,
                        isAuthenticated: true,
                        isLoading: false
                    });

                    return result.user;
                } catch (error) {
                    const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
                    useToastStore.getState().addToast({
                        message: errorMessage,
                        variant: 'error',
                    });
                    set({
                        error: errorMessage,
                        isLoading: false
                    });
                    throw error;
                }
            },

            signInWithEmail: async (email: string) => {
                try {
                    set({ isLoading: true, error: null, email, sessionId: null });

                    const initiateEmailSignIn = httpsCallable<{ email: string }, EmailVerificationResponse>(
                        functions,
                        'callOtp-send',
                    );

                    const result = await initiateEmailSignIn({ email });
                    const { success, message, sessionId } = result.data;

                    if (!success) {
                        throw new Error(message);
                    }

                    useToastStore.getState().addToast({
                        message: 'OTP sent successfully. Please check your email.',
                        variant: 'success',
                    });

                    set({
                        sessionId,
                        isLoading: false,
                    });

                    return result.data;
                } catch (error) {
                    console.log(error);
                    const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
                    set({
                        error: errorMessage,
                        isLoading: false
                    });
                    throw error;
                }
            },

            verifyOTP: async (otp: string) => {
                try {
                    const sessionId = get().sessionId;
                    if (!sessionId) {
                        throw new Error('No active session found');
                    }

                    set({ isLoading: true, error: null });

                    const verifyOTP = httpsCallable<
                        { otp: string; sessionId: string },
                        VerifyOTPResponse
                    >(functions, 'callOtp-verify');

                    const result = await verifyOTP({ otp, sessionId });
                    const { success, message, token } = result.data;

                    if (!success || !token) {
                        useToastStore.getState().addToast({
                            message: 'Failed to verify OTP. Please try again.',
                            variant: 'error',
                        });
                        throw new Error(message || 'Failed to verify OTP');
                    }

                    useToastStore.getState().addToast({
                        message: 'OTP verified successfully. Signing in...',
                        variant: 'success',
                    });

                    const credential = await signInWithCustomToken(auth, token);

                    if (credential.user) {
                        const { userData, membership } = await fetchUserDetailsAndMembership(credential.user);

                        set({
                            user: userData,
                            activeMembership: membership,
                            isAuthenticated: true,
                            isLoading: false,
                            sessionId: null
                        });
                    }

                    return result.data;
                } catch (error) {
                    const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
                    set({
                        error: errorMessage,
                        isLoading: false
                    });
                    throw error;
                }
            },

            signOut: async () => {
                try {
                    set({ isLoading: true, error: null });
                    await signOut(auth);
                    set({
                        user: null,
                        activeMembership: null,
                        isAuthenticated: false,
                        isLoading: false
                    });
                } catch (error) {
                    const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
                    set({
                        error: errorMessage,
                        isLoading: false
                    });
                    throw error;
                }
            },

            updateUserDetails: async (data: UpdateUserData) => {
                try {
                    const currentUser = get().user;
                    if (!currentUser) {
                        throw new Error('No user is currently logged in');
                    }

                    set({ isLoading: true, error: null });
                    const userRef = doc(db, USER, currentUser.uid);
                    await updateDoc(userRef, data);
                    const updatedDoc = await getDoc(userRef);
                    const updatedData = updatedDoc.data();

                    useToastStore.getState().addToast({
                        message: 'User details updated successfully',
                        variant: 'success',
                    });

                    set({
                        user: {
                            ...currentUser,
                            ...updatedData,
                        },
                        isLoading: false,
                    });
                } catch (error) {
                    const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
                    set({
                        error: errorMessage,
                        isLoading: false,
                    });
                    throw error;
                }
            },

            uploadProfileImage: async (file: File) => {
                const currentUser = get().user;
                if (!currentUser) {
                    throw new Error('No user is currently logged in');
                }

                try {
                    set({ isLoading: true, error: null });

                    useToastStore.getState().addToast({
                        message: 'Uploading profile image...',
                        variant: 'info',
                    });

                    // Delete existing profile image if it exists
                    if (currentUser.photoStoragePath) {
                        const oldImageRef = ref(storage, currentUser.photoStoragePath);
                        try {
                            await deleteObject(oldImageRef);
                        } catch (error) {
                            console.warn('Failed to delete old profile image:', error);
                        }
                    }

                    // Generate new storage path
                    const fileExtension = file.name.split('.').pop();
                    const storagePath = `${PROFILE_IMAGES}/${currentUser.uid}/profile.${fileExtension}`;
                    const storageRef = ref(storage, storagePath);

                    // Upload new image
                    await uploadBytes(storageRef, file)
                        .catch((error) => console.error(error));

                    const downloadURL = await getDownloadURL(storageRef);

                    // Update user document with new image URL and storage path
                    const userRef = doc(db, USER, currentUser.uid);
                    await updateDoc(userRef, {
                        photoURL: downloadURL,
                        path: storagePath,
                    });

                    useToastStore.getState().addToast({
                        message: 'Profile image uploaded successfully',
                        variant: 'success',
                    });

                    // Update local state
                    set({
                        user: {
                            ...currentUser,
                            photoURL: downloadURL,
                            photoStoragePath: storagePath,
                        },
                        isLoading: false,
                    });

                    return { downloadURL, storagePath };
                } catch (error) {
                    const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
                    useToastStore.getState().addToast({
                        message: errorMessage,
                        variant: 'error',
                    });
                    set({
                        error: errorMessage,
                        isLoading: false,
                    });
                    throw error;
                }
            },

            clearError: () => set({ error: null }),

            getOrgId: () => {
                const orgId = get().activeMembership?.orgId;
                if (orgId === undefined) {
                    useToastStore.getState().addToast({
                        message: 'No active membership found. Refresh the page and try again.',
                        variant: 'error',
                    });
                    return '';
                }
                return orgId;
            }
        }),
        {
            name: 'auth-storage',
            storage: createEncryptedStorage(),
            partialize: (state) => ({
                user: state.user,
                isAuthenticated: state.isAuthenticated,
                sessionId: state.sessionId,
                activeMembership: state.activeMembership,
            }),
        }
    )
);

export default useAuthStore;