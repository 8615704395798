import React, { useState, useEffect } from 'react';
import { AlertTriangle } from 'lucide-react';
import SignInPage from './signin';
import UserDetailsPage from './detail';
import OTPVerification from './otp';
import SuccessPage from './success';
import useAuthStore from './store';

const AuthUI: React.FC = () => {
    const {
        user,
        sessionId,
        error,
        clearError,
        init,
    } = useAuthStore();

    const [step, setStep] = useState(1);

    useEffect(() => {
        init();
    }, [init]);

    useEffect(() => {
        if (user && user.isNewUser) {
            setStep(3);
        }

        if (sessionId) {
            setStep(2);
        }
    }, [user]);

    return (
        <div className="min-h-screen flex flex-col lg:flex-row">
            {/* Main Content Area */}
            <div className="flex-1 lg:w-1/2 bg-gradient-to-br from-gray-50 to-gray-100">
                {/* Error Notification */}
                {error && (
                    <div className="fixed top-4 left-4 right-4 lg:left-auto lg:right-4 z-50 bg-red-50 text-red-600 px-4 py-2 rounded-lg flex items-center gap-2 animate-in fade-in duration-300 shadow-sm">
                        <AlertTriangle className="h-4 w-4 flex-shrink-0" />
                        <span className="text-sm flex-1">{error}</span>
                        <button
                            onClick={clearError}
                            className="ml-2 text-sm hover:text-red-800 flex-shrink-0"
                        >
                            Dismiss
                        </button>
                    </div>
                )}

                {/* Auth Steps Container */}
                <div className="min-h-screen flex items-center justify-center p-4 sm:p-6 lg:p-8">
                    <div className="w-full max-w-md mx-auto">
                        {step === 1 && (
                            <SignInPage
                                onError={clearError}
                            />
                        )}
                        {step === 2 && (
                            <OTPVerification
                                onNext={() => setStep(3)}
                                onBack={() => setStep(1)}
                                onError={clearError}
                            />
                        )}
                        {step === 3 && (
                            <UserDetailsPage
                                onNext={() => setStep(4)}
                                onBack={() => setStep(2)}
                                onError={clearError}
                            />
                        )}
                        {step === 4 && (
                            <SuccessPage
                                onReset={() => {
                                    setStep(1);
                                    clearError();
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>

            {/* Desktop Side Image */}
            <div className="hidden lg:block lg:w-1/2 relative">
                <div className="absolute inset-0 bg-purple-50">
                    <div className="w-full h-full flex items-center justify-center">
                        <img
                            src="/api/placeholder/800/800"
                            alt="Auth illustration"
                            className="object-cover w-full h-full opacity-90"
                        />
                        <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-purple-700/20" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthUI;