import React, { useState, useMemo, useEffect, KeyboardEvent } from 'react';
import { Phone, Mail, MapPin, Globe, Building, Calendar, Tag, AlertCircle, User } from 'lucide-react';
import { DetailItem, FormValues } from '../blocks/form/interfaces';
import TagsPicker from '../blocks/tag';
import useUserStore from '../contacts/store';

type ErrorState = Partial<Record<keyof FormValues, string>>;
type EditingField = keyof FormValues | null;

const UserDetailsCard: React.FC<{ userId: string }> = ({ userId }) => {
    const [editingField, setEditingField] = useState<EditingField>(null);
    const [errors, setErrors] = useState<ErrorState>({});
    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [localValues, setLocalValues] = useState<Partial<FormValues>>({});

    const {
        userDetails,
        fetchContactDetails,
        updateContactField,
        error: storeError,
    } = useUserStore();

    useEffect(() => {
        fetchContactDetails(userId);
    }, [userId]);

    useEffect(() => {
        if (userDetails) {
            setLocalValues(userDetails);
        }
    }, [userDetails]);

    const userLocale = useMemo(() => navigator.language || 'en-US', []);
    const dateFormatter = useMemo(() => new Intl.DateTimeFormat(userLocale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }), [userLocale]);

    const formatDate = (dateString: string): string => {
        if (!dateString) return '';
        try {
            const date = new Date(dateString);
            return dateFormatter.format(date);
        } catch {
            return dateString;
        }
    };

    const validateField = (field: keyof FormValues, value: string): string => {
        if (!value) return '';

        switch (field) {
            case 'email': {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailRegex.test(value) ? '' : 'Invalid email format';
            }

            case 'name': {
                return value.length < 2 ? 'Name must be at least 2 characters' : '';
            }

            case 'dob': {
                const date = new Date(value);
                const isValidDate = date instanceof Date && !isNaN(date.getTime());
                if (!isValidDate) return 'Invalid date';
                if (date > new Date()) return 'Date cannot be in the future';
                return '';
            }

            case 'url': {
                try {
                    new URL(value);
                    return '';
                } catch {
                    return 'Invalid URL format';
                }
            }

            default:
                return '';
        }
    };

    const getPlaceholder = (field: keyof FormValues): string => {
        const placeholders: Record<keyof FormValues, string> = {
            totalCalls: 'Add total calls',
            avgCallTime: 'Add average call time',
            tags: 'Add tags',
            name: 'Add name',       
            phone: 'Add phone number',
            email: 'Add email address',
            address: 'Add address',
            dob: 'Add date of birth',
            url: 'Add website URL',
            company: 'Add company name'
        };
        return placeholders[field];
    };

    const handleEdit = (field: EditingField, value: any): void => {
        if (!field) return;

        const error = typeof value === 'string' ? validateField(field, value) : '';
        setErrors(prev => ({
            ...prev,
            [field]: error
        }));

        setLocalValues(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const syncWithFirebase = async (field: keyof FormValues, value: any) => {
        const error = typeof value === 'string' ? validateField(field, value) : '';
        if (!error) {
            await updateContactField(userId, field, value);
        }
        return error;
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const date = e.target.value;
        handleEdit('dob', date);
    };

    const handleBlur = async (field: keyof FormValues): Promise<void> => {
        const value = localValues[field];
        if (value !== userDetails?.[field]) {
            const error = await syncWithFirebase(field, value);
            setErrors(prev => ({
                ...prev,
                [field]: error
            }));
        }
        setEditingField(null);
    };

    const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>, field: keyof FormValues) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            await handleBlur(field);
            // Remove focus from the input
            (e.target as HTMLInputElement).blur();
        }
    };

    const handleTagsChange = async (tags: any[]) => {
        handleEdit('tags', tags);
        await syncWithFirebase('tags', tags);
    };

    const handleCalendarClose = async () => {
        if (localValues.dob !== userDetails?.dob) {
            await syncWithFirebase('dob', localValues.dob);
        }
        setShowCalendar(false);
    };

    const renderValue = (detail: DetailItem): React.ReactNode => {
        if (detail.field === 'tags') {
            return (
                <TagsPicker
                    value={localValues.tags || []}
                    onChange={handleTagsChange}
                />
            );
        }

        if (detail.field === 'dob') {
            return (
                <div className="relative">
                    <div
                        onClick={() => setShowCalendar(!showCalendar)}
                        className={`cursor-pointer ${errors[detail.field] ? 'text-red-500' : localValues.dob ? 'text-gray-900' : 'text-gray-400'}`}
                    >
                        {localValues.dob ? formatDate(localValues.dob) : getPlaceholder(detail.field)}
                    </div>
                    {showCalendar && (
                        <div className="absolute left-0 mt-1 bg-white shadow-lg rounded-md z-10">
                            <input
                                type="date"
                                onChange={handleDateChange}
                                onBlur={handleCalendarClose}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleCalendarClose();
                                        (e.target as HTMLInputElement).blur();
                                    }
                                }}
                                className="p-2 border rounded"
                                value={localValues.dob || ''}
                                autoFocus
                            />
                        </div>
                    )}
                </div>
            );
        }

        const value = localValues[detail.field] || '';

        return editingField === detail.field ? (
            <input
                type="text"
                value={typeof value === 'string' ? value : ''}
                onChange={(e) => handleEdit(detail.field, e.target.value)}
                onBlur={() => handleBlur(detail.field)}
                onKeyDown={(e) => handleKeyDown(e, detail.field)}
                placeholder={getPlaceholder(detail.field)}
                autoFocus
                className={`w-full bg-transparent border-b ${errors[detail.field] ? 'border-red-500' : 'border-blue-500'} outline-none ${errors[detail.field] ? 'text-red-500' : 'text-gray-900'} placeholder-gray-400`}
            />
        ) : (
            <span
                className={`cursor-pointer ${errors[detail.field] ? 'text-red-500' : value ? 'text-gray-900' : 'text-gray-400'}`}
                onClick={() => setEditingField(detail.field)}
            >
                {value || getPlaceholder(detail.field)}
            </span>
        );
    };

    const renderDetailItem = (detail: DetailItem) => {
        return (
            <div key={detail.label} className="space-y-2">
                <div className="flex items-center space-x-2">
                    <div className="text-blue-600">{detail.icon}</div>
                    <span className="text-sm text-gray-500">{detail.label}</span>
                    {errors[detail.field] && (
                        <div className="relative group">
                            <AlertCircle className="h-4 w-4 text-red-500" />
                            <div className="hidden group-hover:block absolute left-0 bottom-full mb-2 bg-red-100 text-red-600 text-xs p-2 rounded shadow-sm whitespace-nowrap">
                                {errors[detail.field]}
                            </div>
                        </div>
                    )}
                </div>
                <div className="pl-7">
                    {renderValue(detail)}
                </div>
            </div>
        );
    };

    const details: DetailItem[] = [
        { icon: <Tag className="w-5 h-5" />, label: 'Tags', value: localValues.tags || [], field: 'tags' },
        { icon: <User className="w-5 h-5" />, label: 'Name', value: localValues.name || '', field: 'name', validate: true },
        { icon: <Mail className="w-5 h-5" />, label: 'Email', value: localValues.email || '', field: 'email', validate: true },
        { icon: <Phone className="w-5 h-5" />, label: 'Phone', value: localValues.phone || '', field: 'phone' },
        { icon: <MapPin className="w-5 h-5" />, label: 'Address', value: localValues.address || '', field: 'address' },
        { icon: <Calendar className="w-5 h-5" />, label: 'DOB', value: localValues.dob || '', field: 'dob', validate: true },
        { icon: <Globe className="w-5 h-5" />, label: 'URL', value: localValues.url || '', field: 'url', validate: true },
        { icon: <Building className="w-5 h-5" />, label: 'Company', value: localValues.company || '', field: 'company' }
    ];

    if (storeError) {
        return <div className="p-4 text-red-500">Error: {storeError}</div>;
    }

    return (
        <div className="bg-white p-4 mx-auto flex-grow">
            <div className="space-y-6">
                <div className="space-y-4">
                    <h3 className="text-base font-medium text-gray-900">User Details</h3>
                    <div className="space-y-6">
                        {details.map(detail => renderDetailItem(detail))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDetailsCard;

