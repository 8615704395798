import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/navbar';
import Home from './pages/home';
import { ProtectedRoute } from './router/protected';
import AuthUI from './components/auth';
import UserProfile from './pages/account';
import Settings from './pages/settings';
import { ToastContainer } from './components/toast';
import useCredentialsStore from './components/credentials/store';
import Contacts from './pages/contacts';

const App = () => {
  useEffect(() => {
    useCredentialsStore.getState().loadFromFirestore();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <ProtectedRoute requireAuth={false}>
              <AuthUI />
              <ToastContainer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/*"
          element={
            <ProtectedRoute requireAuth={true}>
              {/* Main Layout Container */}
              <div className="flex min-h-screen bg-gray-50">
                {/* Navbar - Fixed width using flex-none */}
                <div className="flex-none bg-white border-r border-gray-200">
                  <div className="sticky top-0 h-screen overflow-y-auto">
                    <Navbar />
                  </div>
                </div>

                {/* Main Content Area - Flexible width */}


                <div className="flex-1 min-w-0">
                  {/* Toast Container */}
                  <ToastContainer />
                  <div className="container mx-auto">
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/contacts/*" element={<Contacts />} />
                      <Route path="/me/*" element={<UserProfile />} />
                      <Route path="/settings/*" element={<Settings />} />
                    </Routes>
                  </div>
                </div>


              </div>
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;