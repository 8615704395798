import React from 'react';

export interface BaseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
}

export const BaseButton: React.FC<BaseButtonProps> = ({
  children,
  className = '',
  ...props
}) => {
  return (
    <button
      className={`w-full py-3 px-6 rounded-lg flex items-center justify-center gap-3 
                 transition-all duration-200 ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};