import React, { useState, useRef, useEffect } from 'react';
import { Tag as TagIcon, Plus, X } from 'lucide-react';
import { ITag, useTagsStore } from './store';

interface TagsPickerProps {
  value?: ITag[];
  onChange?: (tags: ITag[]) => void;
}

const TagsPicker: React.FC<TagsPickerProps> = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { 
    tags,
    loading,
    error,
    selectedTags,
    fetchTags,
    createTag,
    setSelectedTags
  } = useTagsStore();

  // Initialize tags
  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  // Sync with external value if provided
  useEffect(() => {
    if (value) {
      setSelectedTags(value);
    }
  }, [value, setSelectedTags]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        setSearchTerm('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const filteredTags = tags.filter(tag => 
    tag.label.toLowerCase().includes(searchTerm.toLowerCase()) &&
    !selectedTags.some(selectedTag => selectedTag.id === tag.id)
  );

  const handleAddTag = async (tag: ITag) => {
    const newTags = [...selectedTags, tag];
    setSelectedTags(newTags);
    onChange?.(newTags);
    setSearchTerm('');
  };

  const handleRemoveTag = (tagId: string) => {
    const newTags = selectedTags.filter(tag => tag.id !== tagId);
    setSelectedTags(newTags);
    onChange?.(newTags);
  };

  const handleCreateTag = async () => {
    if (searchTerm.trim()) {
      try {
        const newTag = await createTag(searchTerm.trim());
        handleAddTag(newTag);
        setIsOpen(false);
      } catch (error) {
        // Handle error (e.g., tag already exists)
        console.error('Failed to create tag:', error);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && searchTerm) {
      e.preventDefault();
      handleCreateTag();
    }
  };

  if (error) {
    return <div className="text-red-500 text-sm">Error loading tags: {error}</div>;
  }

  return (
    <div className="relative" ref={dropdownRef}>
      <div className={`flex ${selectedTags.length > 2 ? 'flex-col' : 'items-center'} gap-2`}>
        <div className={`flex flex-wrap gap-2 ${selectedTags.length > 2 ? 'w-full' : ''}`}>
          {selectedTags.map(tag => (
            <span
              key={tag.id}
              className="inline-flex items-center gap-1 px-2 py-1 bg-blue-50 text-blue-700 text-sm rounded"
            >
              <TagIcon className="w-3 h-3" />
              {tag.label}
              <button
                onClick={() => handleRemoveTag(tag.id)}
                className="hover:text-blue-900"
              >
                <X className="w-3 h-3" />
              </button>
            </span>
          ))}
        </div>
        <button
          onClick={() => {
            setIsOpen(!isOpen);
            setTimeout(() => inputRef.current?.focus(), 0);
          }}
          className="text-blue-500 hover:text-blue-700 inline-flex items-center gap-1"
          disabled={loading}
        >
          <Plus className="w-4 h-4" />
          {selectedTags.length === 0 && 'Add tag'}
        </button>
      </div>

      {isOpen && (
        <div className="absolute mt-2 right-0 w-64 bg-white border rounded-md shadow-lg z-50">
          <div className="p-2">
            <input
              ref={inputRef}
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Search or add new tag..."
              className="w-full px-2 py-1 text-sm border rounded focus:outline-none focus:border-blue-500"
              autoFocus
            />
          </div>
          <div className="max-h-48 overflow-y-auto">
            {loading ? (
              <div className="px-4 py-2 text-sm text-gray-500">Loading tags...</div>
            ) : (
              <>
                {filteredTags.map(tag => (
                  <button
                    key={tag.id}
                    onClick={() => handleAddTag(tag)}
                    className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  >
                    {tag.label}
                  </button>
                ))}
                {searchTerm && !filteredTags.some(tag => tag.label.toLowerCase() === searchTerm.toLowerCase()) && (
                    <button
                    onClick={handleCreateTag}
                    className="w-full px-4 py-2 text-left text-sm text-blue-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                    >
                    Create &quot;{searchTerm}&quot;
                    </button>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TagsPicker;