import React, { ChangeEvent } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import FormLabel from './label';

interface FormInputProps {
  id: string;
  name: string;
  type?: string;
  value: string;
  label?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  description?: string;
}

const FormInput: React.FC<FormInputProps> = ({
  id,
  name,
  type = "text",
  value,
  label,
  onChange,
  placeholder,
  className,
  description
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    let processedValue = inputValue;
    let syntheticEvent = e;

    switch (type) {
      case 'number':
      case 'tel':
        processedValue = inputValue.replace(/[^0-9]/g, '')
        syntheticEvent = {
          ...e,
          target: {
            ...e.target,
            name,
            value: processedValue
          }
        } as ChangeEvent<HTMLInputElement>;
        break;
      default:
        break;
    }
    onChange(syntheticEvent);
  };

  // Determine input type for rendering
  const inputType = type === 'password'
    ? (showPassword ? 'text' : 'password')
    : (type === 'number' ? 'text' : type); // Change number to text to prevent spinner arrows

  // Build className string with type-specific styles
  const inputClassName = `
    w-full px-4 py-3 rounded-lg border border-gray-300 
    focus:ring-2 focus:ring-blue-500 focus:border-blue-500 
    outline-none transition-all duration-200 
    bg-white dark:border-gray-600 dark:focus:border-blue-400 
    placeholder:text-base placeholder-vertical-center text-black
    ${type === 'number' || type === 'tel' ? '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none' : ''}
    ${className || ''}
  `.trim();

  return (
    <div className="relative w-full">
      <FormLabel htmlFor={name} description={description} label={label} />
      <input
        id={id}
        name={name}
        type={inputType}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={inputClassName}
      />
      {type === 'password' && (
        <button
          type="button"
          onClick={togglePassword}
          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
        >
          {showPassword ? (
            <EyeOff className="h-4 w-4" />
          ) : (
            <Eye className="h-4 w-4" />
          )}
        </button>
      )}
    </div>
  );
};

export default FormInput;