import React, { useState, useRef, ChangeEvent, KeyboardEvent } from 'react';
import {
    Mail,
    AlertTriangle,
    Upload,
    Pencil,
    Phone,
    FileText,
    History,
    Settings,
    UserX,
    Bell,
    MessageSquare,
    Inbox,
    Loader2
} from 'lucide-react';
import useAuthStore from '../components/auth/store';
import ContactAvatar from '@/components/blocks/avatar';

interface CommunicationPrefs {
    marketing: boolean;
    serviceUpdates: boolean;
    productNews: boolean;
    callNotifications: boolean;
}

const UserProfile: React.FC = () => {
    const { user, updateUserDetails, signOut, uploadProfileImage } = useAuthStore();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [deleteReason, setDeleteReason] = useState('');
    const [deleteReasonError, setDeleteReasonError] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [nameInput, setNameInput] = useState(user?.displayName || '');

    const [communicationPrefs, setCommunicationPrefs] = useState<CommunicationPrefs>({
        marketing: user?.communicationPreferences?.marketing ?? true,
        serviceUpdates: user?.communicationPreferences?.serviceUpdates ?? true,
        productNews: user?.communicationPreferences?.productNews ?? true,
        callNotifications: user?.communicationPreferences?.callNotifications ?? true
    });

    const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;

        try {
            setIsUploading(true);
            await uploadProfileImage(file);
        } catch (error) {
            console.error('Error uploading image:', error);
            alert('Failed to upload image. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };

    const handleCommunicationToggle = async (key: keyof CommunicationPrefs) => {
        const newPrefs = {
            ...communicationPrefs,
            [key]: !communicationPrefs[key]
        };
        setCommunicationPrefs(newPrefs);

        await updateUserDetails({
            communicationPreferences: {
                ...user?.communicationPreferences,
                ...newPrefs
            }
        });
    };

    const handleProceedToConfirm = () => {
        if (!deleteReason.trim()) {
            setDeleteReasonError('Please provide a reason for deleting your account');
            return;
        }
        setDeleteReasonError('');
        setShowDeleteConfirm(true);
    };

    const handleDeleteRequest = async () => {
        try {
            await signOut();
        } catch (error) {
            console.error('Error deleting account:', error);
        }
    };

    const handleNameUpdate = () => {
        if (!user || nameInput === user.displayName) return;
        updateUserDetails({ displayName: nameInput });
    };

    const handleNameKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.currentTarget.blur();
            handleNameUpdate();
        }
    };

    const handleNameBlur = () => {
        handleNameUpdate();
    };

    if (!user) {
        return <div>Please sign in to view your profile.</div>;
    }

    const deletionConsequences = [
        {
            icon: <Phone className="h-4 w-4" />,
            title: "Phone Services",
            details: "Your phone number and all call forwarding settings will be removed"
        },
        {
            icon: <History className="h-4 w-4" />,
            title: "Call History",
            details: "All call recordings and history will be permanently deleted"
        },
        {
            icon: <FileText className="h-4 w-4" />,
            title: "Notes & Records",
            details: "All call notes, contact information, and saved records will be erased"
        },
        {
            icon: <Settings className="h-4 w-4" />,
            title: "Account Settings",
            details: "All your preferences, configurations, and personalizations will be lost"
        },
        {
            icon: <UserX className="h-4 w-4" />,
            title: "Contact Data",
            details: "Your contacts and related data will be permanently removed"
        }
    ];

    const communicationOptions = [
        {
            key: 'marketing',
            icon: <Inbox className="h-4 w-4" />,
            title: "Marketing Communications",
            description: "Receive updates about promotions and special offers"
        },
        {
            key: 'serviceUpdates',
            icon: <Bell className="h-4 w-4" />,
            title: "Service Updates",
            description: "Important notifications about your service and account"
        },
        {
            key: 'productNews',
            icon: <MessageSquare className="h-4 w-4" />,
            title: "Product News",
            description: "Learn about new features and improvements"
        },
        {
            key: 'callNotifications',
            icon: <Phone className="h-4 w-4" />,
            title: "Call Notifications",
            description: "Receive notifications about missed calls and voicemails"
        }
    ];

    return (
        <div className="max-w-2xl mx-auto p-2 pt-[3vh]">
            <div className="bg-white rounded-lg shadow-sm">
                {/* Header Section */}
                <div className="p-6">
                    <div className="flex items-center space-x-4">
                        <div className="relative group">
                            {user.photoURL ? (
                                <img
                                    src={user.photoURL}
                                    alt="Profile"
                                    className="h-20 w-20 rounded-full object-cover cursor-pointer"
                                    onClick={() => fileInputRef.current?.click()}
                                />
                            ) : (
                                <ContactAvatar
                                    input={user.displayName || ''}
                                    size="md"
                                    className="h-20 w-20 rounded-full object-cover cursor-pointer"
                                    onClick={() => fileInputRef.current?.click()}
                                />
                            )}
                            {isUploading ? (
                                <div className="absolute inset-0 flex items-center justify-center rounded-full bg-black bg-opacity-50">
                                    <Loader2 className="h-6 w-6 text-white animate-spin" />
                                </div>
                            ) : (
                                <div
                                    className="absolute inset-0 flex items-center justify-center rounded-full bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer"
                                    onClick={() => fileInputRef.current?.click()}
                                >
                                    <Upload className="h-6 w-6 text-white" />
                                </div>
                            )}
                            <input
                                type="file"
                                ref={fileInputRef}
                                className="hidden"
                                accept="image/*"
                                onChange={handleImageUpload}
                                disabled={isUploading}
                            />
                            <div className="absolute bottom-0 right-0 h-3 w-3 rounded-full bg-green-500 border-2 border-white" />
                        </div>

                        <div>
                            <div className="group relative flex items-center">
                                <span className="text-xl font-semibold">{user.displayName}</span>
                            </div>
                            <div className="flex items-center space-x-2 text-gray-500 mt-1">
                                <Mail className="h-4 w-4" />
                                <span>{user.email}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Details Section */}
                <div className="px-6 pb-6">
                    <div className="space-y-4">
                        <div className="grid grid-cols-1 gap-4">
                            <div className="border rounded-lg p-4">
                                <div className="flex flex-col space-y-2">
                                    <label className="text-gray-500 text-sm">Name</label>
                                    <input
                                        value={nameInput}
                                        onChange={(e) => setNameInput(e.target.value)}
                                        onBlur={handleNameBlur}
                                        onKeyDown={handleNameKeyDown}
                                        className="font-medium bg-transparent border-b border-transparent hover:border-gray-300 focus:border-gray-400 focus:outline-none"
                                    />
                                    <Pencil className="h-4 w-4 ml-2 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity" />
                                </div>
                            </div>

                            <div className="border rounded-lg p-4">
                                <div className="flex flex-col space-y-2">
                                    <label className="text-gray-500 text-sm">Email</label>
                                    <span className="font-medium text-gray-500">{user.email}</span>
                                </div>
                            </div>

                            {user.phoneNumber && (
                                <div className="border rounded-lg p-4">
                                    <div className="flex flex-col space-y-2">
                                        <label className="text-gray-500 text-sm">Phone</label>
                                        <span className="font-medium text-gray-500">{user.phoneNumber}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Communication Preferences Section */}
                <div className="border-t">
                    <div className="p-6">
                        <h3 className="text-lg font-medium mb-4">Communication Preferences</h3>
                        <div className="space-y-4">
                            {communicationOptions.map(({ key, icon, title, description }) => (
                                <div key={key} className="flex items-center justify-between p-3 border rounded-lg hover:bg-gray-50">
                                    <div className="flex items-start space-x-3">
                                        <div className="text-gray-500 mt-1">
                                            {icon}
                                        </div>
                                        <div>
                                            <h4 className="text-sm font-medium text-gray-900">{title}</h4>
                                            <p className="text-sm text-gray-500">{description}</p>
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => handleCommunicationToggle(key as keyof CommunicationPrefs)}
                                        className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none ${communicationPrefs[key as keyof CommunicationPrefs]
                                            ? 'bg-green-500'
                                            : 'bg-gray-200'
                                            }`}
                                    >
                                        <span
                                            className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${communicationPrefs[key as keyof CommunicationPrefs]
                                                ? 'translate-x-5'
                                                : 'translate-x-0'
                                                }`}
                                        />
                                    </button>
                                </div>
                            ))}
                            <p className="text-sm text-gray-500 mt-4">
                                You can opt out of non-essential communications at any time. Service-related messages may still be sent for account security and legal compliance.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Delete Account Section */}
                <div className="border-t">
                    <div className="p-6">
                        <div className="rounded-lg border border-red-200 bg-red-50 p-4">
                            {!isDeleting ? (
                                <div className="flex gap-[1rem]">
                                    <AlertTriangle className="h-5 w-5" />
                                    <button
                                        onClick={() => setIsDeleting(true)}
                                        className="text-red-600 hover:text-red-800 font-medium"
                                    >
                                        Delete my account
                                    </button>
                                </div>
                            ) : (
                                <div className="space-y-6">
                                    <div className="bg-white rounded-lg p-4 border border-red-100">
                                        <h4 className="text-red-800 font-medium mb-3">
                                            You will lose access to the following features and data:
                                        </h4>
                                        <div className="space-y-4">
                                            {deletionConsequences.map((item, index) => (
                                                <div key={index} className="flex items-start space-x-3">
                                                    <div className="text-red-600 mt-1">
                                                        {item.icon}
                                                    </div>
                                                    <div>
                                                        <h5 className="text-sm font-medium text-gray-900">
                                                            {item.title}
                                                        </h5>
                                                        <p className="text-sm text-gray-600">
                                                            {item.details}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="mt-6">
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Please tell us why you're leaving:
                                            </label>
                                            <textarea
                                                value={deleteReason}
                                                onChange={(e) => {
                                                    setDeleteReason(e.target.value);
                                                    if (e.target.value.trim()) {
                                                        setDeleteReasonError('');
                                                    }
                                                }}
                                                placeholder="Your feedback helps us improve our service..."
                                                className={`w-full p-2 border rounded-md text-sm ${
                                                    deleteReasonError ? 'border-red-500' : 'border-gray-300'
                                                }`}
                                                rows={3}
                                            />
                                            {deleteReasonError && (
                                                <p className="mt-1 text-sm text-red-600">
                                                    {deleteReasonError}
                                                </p>
                                            )}
                                        </div>
                                        
                                        {!showDeleteConfirm ? (
                                            <div className="mt-6 flex space-x-3">
                                                <button
                                                    onClick={handleProceedToConfirm}
                                                    className="bg-red-600 text-white px-4 py-2 rounded-md text-sm hover:bg-red-700"
                                                >
                                                    Continue with Account Deletion
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        setIsDeleting(false);
                                                        setShowDeleteConfirm(false);
                                                        setDeleteReason('');
                                                        setDeleteReasonError('');
                                                    }}
                                                    className="text-gray-600 px-4 py-2 rounded-md text-sm hover:text-gray-800"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="mt-6 space-y-4">
                                                <div className="text-sm text-red-600 bg-red-50 p-3 rounded">
                                                    ⚠️ This action cannot be undone. All data will be permanently deleted.
                                                </div>
                                                <div className="flex space-x-3">
                                                    <button
                                                        onClick={handleDeleteRequest}
                                                        className="bg-red-600 text-white px-4 py-2 rounded-md text-sm hover:bg-red-700"
                                                    >
                                                        Yes, Permanently Delete My Account
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            setShowDeleteConfirm(false);
                                                        }}
                                                        className="text-gray-600 px-4 py-2 rounded-md text-sm hover:text-gray-800"
                                                    >
                                                        No, Keep My Account
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;