import { create } from 'zustand';
import { Call, Device } from '@twilio/voice-sdk';
import useCredentialsStore from '@/components/credentials/store';
import { useToastStore } from '@/components/toast/store';

// Types
interface CallDetails {
  id: string;
  to: string;
  from?: string;
  customData?: any;
}

interface CallMetadata {
  callId: string;
  caller: string;
  recipient?: string;
  startTime: string;
  endTime?: string;
  duration?: number;
  call: Call;
  isOnHold?: boolean;
  isMuted?: boolean;
}

interface IncomingCallData {
  callId: string;
  caller: string;
  timestamp: string;
  call: Call;
}

type CallStatus = 'idle' | 'connecting' | 'connected' | 'disconnected' | 'error' | 'hold' | 'reconnecting';

interface CallStore {
  // State
  device: Device | null;
  currentCall: CallMetadata | null;
  callStatus: CallStatus;
  incomingCall: IncomingCallData | null;
  callHistory: CallMetadata[];
  error: string | null;

  // Device Management
  initializeDevice: (token: string) => Promise<void>;

  // Call Management
  makeCall: (callDetails: CallDetails) => Promise<void>;
  answerCall: () => void;
  rejectCall: () => void;
  endCall: () => void;

  // Call Controls
  toggleMute: () => void;
  toggleHold: () => void;
  sendDigit: (digit: string) => void;
  sendDigits: (digits: string) => void;

  // Utility
  clearError: () => void;
}

// Enhanced error handling utilities
const TWILIO_ERROR_MESSAGES = {
  '51000': 'Invalid access token',
  '51001': 'Access token expired',
  '51002': 'Access token not yet valid',
  '51003': 'Invalid access token grants',
  '51004': 'Invalid access token signature',
  '52001': 'Authentication failed - please check your credentials',
  '52002': 'Authorization failed - insufficient permissions',
  '52003': 'Authorization revoked',
  '52004': 'Invalid authentication method',
  '53000': 'Connection lost. Attempting to reconnect...',
  '53001': 'Signaling connection error',
  '53002': 'Signaling connection timeout',
  '53003': 'Signaling connection closed',
  '53100': 'Invalid signaling message',
  '53101': 'Invalid signaling state',
  '53102': 'Signaling transport error',
  '53103': 'Signaling protocol error',
  '53104': 'Signaling handshake error',
  '53105': 'Signaling negotiation error',
  '54001': 'Microphone not accessible',
  '54002': 'Microphone permission denied',
  '54003': 'No audio input devices available',
  '54004': 'Audio input device error',
  '54005': 'Media stream failed',
  '54006': 'Media connection failed',
  '54007': 'Media quality degraded',
  '54008': 'ICE connection failed',
  '54009': 'ICE gathering failed',
  '54010': 'DTLS connection failed',
  '54011': 'RTP connection failed',
  '55001': 'Client initialization failed',
  '55002': 'Client configuration invalid',
  '55003': 'Client not registered',
  '55004': 'Client registration failed',
  '55005': 'Client deregistration failed',
  '55006': 'Client resource exhausted',
  '56001': 'Server error',
  '56002': 'Server capacity reached',
  '56003': 'Server unavailable',
  '56004': 'Server timeout',
  '57001': 'Network connection lost',
  '57002': 'Network quality degraded',
  '57003': 'Network timeout',
  '57004': 'Network DNS resolution failed',
  '58001': 'Call failed to connect',
  '58002': 'Call dropped',
  '58003': 'Call rejected',
  '58004': 'Call not found',
  '58005': 'Invalid call state',
  '58006': 'Call timeout',
  '59001': 'Rate limit exceeded',
  '59002': 'Too many requests',
  '59003': 'Resource quota exceeded'
} as const;

type ErrorSeverity = 'error' | 'warning' | 'info';

interface ErrorHandlingConfig {
  severity: ErrorSeverity;
  retry: boolean;
  logLevel: 'error' | 'warn' | 'info';
}

const ERROR_HANDLING_CONFIG: { [key: string]: ErrorHandlingConfig } = {
  '51': { severity: 'error', retry: false, logLevel: 'error' },
  '52': { severity: 'error', retry: false, logLevel: 'error' },
  '53': { severity: 'warning', retry: true, logLevel: 'warn' },
  '54': { severity: 'error', retry: true, logLevel: 'error' },
  '55': { severity: 'error', retry: true, logLevel: 'error' },
  '56': { severity: 'error', retry: true, logLevel: 'error' },
  '57': { severity: 'warning', retry: true, logLevel: 'warn' },
  '58': { severity: 'error', retry: false, logLevel: 'error' },
  '59': { severity: 'error', retry: false, logLevel: 'error' }
};

const getErrorConfig = (errorCode: string): ErrorHandlingConfig => {
  const category = errorCode.slice(0, 2);
  return ERROR_HANDLING_CONFIG[category] || { severity: 'error', retry: false, logLevel: 'error' };
};

const handleConnectionError = async (error: any, device: Device | null, set: any) => {
  const errorCode = error?.code?.toString() || error?.message?.match(/\((\d+)\)/)?.[1] || '0';
  const errorMessage = TWILIO_ERROR_MESSAGES[errorCode as keyof typeof TWILIO_ERROR_MESSAGES] || error?.message || 'Unknown error occurred';
  const config = getErrorConfig(errorCode);

  console[config.logLevel]('Twilio error:', {
    code: errorCode,
    message: errorMessage,
    originalError: error
  });

  useToastStore.getState().addToast({
    message: errorMessage,
    variant: config.severity,
    duration: config.severity === 'error' ? 7000 : 5000
  });

  set({ error: errorMessage });

  if (config.retry && device) {
    set({ callStatus: 'reconnecting' });
    await attemptReconnection(device, set, errorCode);
  } else {
    set({ callStatus: 'error' });
  }

  switch (errorCode.slice(0, 2)) {
    case '51':
      try {
        await useCredentialsStore.getState().callUserToken({ force: true });
      } catch (refreshError) {
        useToastStore.getState().addToast({
          message: 'Failed to refresh access token. Please log in again.',
          variant: 'error'
        });
      }
      break;

    case '54':
      if (errorCode === '54002') {
        requestMediaPermissions();
      }
      break;

    case '57':
      startNetworkMonitoring(device, set);
      break;

    case '59':
      handleRateLimiting();
      break;
  }
};



const requestMediaPermissions = async () => {
  try {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    useToastStore.getState().addToast({
      message: 'Microphone access granted',
      variant: 'success'
    });
  } catch (error) {
    useToastStore.getState().addToast({
      message: 'Failed to get microphone access. Please check your browser settings.',
      variant: 'error'
    });
  }
};

const startNetworkMonitoring = (device: Device | null, set: any) => {
  if (!device) return;

  const monitor = () => {
    if (navigator.onLine) {
      attemptReconnection(device, set);
    } else {
      useToastStore.getState().addToast({
        message: 'Network connection lost. Please check your internet connection.',
        variant: 'warning'
      });
    }
  };

  window.addEventListener('online', monitor);
  window.addEventListener('offline', () => {
    set({ callStatus: 'error' });
    useToastStore.getState().addToast({
      message: 'Network connection lost',
      variant: 'error'
    });
  });

  return () => {
    window.removeEventListener('online', monitor);
    window.removeEventListener('offline', monitor);
  };
};

let rateLimitBackoff = 1000;
const handleRateLimiting = () => {
  useToastStore.getState().addToast({
    message: `Rate limit exceeded. Retrying in ${rateLimitBackoff / 1000} seconds...`,
    variant: 'warning'
  });

  setTimeout(() => {
    rateLimitBackoff = Math.min(rateLimitBackoff * 5, 32000);
  }, rateLimitBackoff);
};

const attemptReconnection = async (device: Device | null, set: any, errorCode?: string) => {
  const maxAttempts = errorCode?.startsWith('53') ? 5 : 3;
  let attempts = 0;

  const tryReconnect = async (): Promise<boolean> => {
    try {
      if (!device) return false;

      attempts++;
      const backoffTime = Math.min(60000 * Math.pow(2, attempts - 1), 60000);
      await new Promise(resolve => setTimeout(resolve, backoffTime));

      await device.register();

      useToastStore.getState().addToast({
        message: 'Successfully reconnected',
        variant: 'success'
      });

      set({
        callStatus: 'connected',
        error: null
      });

      return true;
    } catch (error) {
      if (attempts < maxAttempts) {
        useToastStore.getState().addToast({
          message: `Reconnection attempt ${attempts} failed. Retrying...`,
          variant: 'warning'
        });
        return tryReconnect();
      }

      useToastStore.getState().addToast({
        message: 'Failed to reconnect after multiple attempts. Please try again later.',
        variant: 'error'
      });

      set({
        error: 'Maximum reconnection attempts reached',
        callStatus: 'error'
      });

      return false;
    }
  };

  return tryReconnect();
};

const useCallStore = create<CallStore>((set, get) => ({
  device: null,
  currentCall: null,
  callStatus: 'idle',
  incomingCall: null,
  callHistory: [],
  error: null,

  initializeDevice: async (token: string) => {
    try {
      const device = new Device(token, {
        edge: ['ashburn', 'dublin', 'roaming'],
        codecPreferences: [Call.Codec.PCMU, Call.Codec.Opus]
      });

      device.on('registered', () => {
        useToastStore.getState().addToast({
          message: `${useCredentialsStore.getState().credentials.countryCode} ${useCredentialsStore.getState().credentials.phoneNumber} is ready for use.`,
          variant: 'success'
        });
      });

      device.on('error', (error) => {
        handleConnectionError(error, device, set);
      });

      device.on('incoming', (call) => {
        set({
          incomingCall: {
            callId: call.parameters.CallSid || '',
            caller: call.parameters.From || '',
            timestamp: new Date().toISOString(),
            call
          }
        });

        call.on('accept', () => {
          set({ callStatus: 'connected' });
        });

        call.on('disconnect', () => {
          set({ callStatus: 'disconnected', incomingCall: null });
        });

        call.on('error', (error: Error) => {
          handleConnectionError(error, device, set);
        });
      });

      await device.register();
      set({ device });
    } catch (error) {
      handleConnectionError(error, null, set);
    }
  },

  makeCall: async (callDetails: CallDetails) => {
    const { device } = get();
    if (!device) {
      set({ error: 'Device not initialized' });
      return;
    }

    try {
      const connectOptions = {
        params: {
          To: callDetails.to.replace(/\+/g, ''),
          From: callDetails.from || `${useCredentialsStore.getState().credentials.countryCode.replace(/\+/g, '')}${useCredentialsStore.getState().credentials.phoneNumber}`,
          CustomParam: JSON.stringify({
            id: callDetails.id,
            ...callDetails.customData
          })
        }
      };

      const call = await device.connect(connectOptions);

      call.on('accept', () => {
        set({
          callStatus: 'connected',
          currentCall: {
            callId: callDetails.id,
            caller: connectOptions.params.From,
            recipient: connectOptions.params.To,
            startTime: new Date().toISOString(),
            call,
            isOnHold: false,
            isMuted: false
          }
        });
      });

      call.on('disconnect', () => {
        const { currentCall, callHistory } = get();
        if (currentCall) {
          const endedCall = {
            ...currentCall,
            endTime: new Date().toISOString(),
            duration: (new Date().getTime() - new Date(currentCall.startTime).getTime()) / 1000
          };
          set({
            callStatus: 'disconnected',
            currentCall: null,
            callHistory: [...callHistory, endedCall]
          });
        }
      });

      call.on('reconnecting', () => {
        set({ callStatus: 'reconnecting' });
      });

      call.on('reconnected', () => {
        set({ callStatus: 'connected' });
      });

      call.on('error', (error) => {
        handleConnectionError(error, device, set);
      });

      set({ callStatus: 'connecting' });
    } catch (error) {
      handleConnectionError(error, device, set);
    }
  },

  answerCall: () => {
    const { incomingCall } = get();
    if (!incomingCall) {
      set({ error: 'No incoming call to answer' });
      return;
    }

    try {
      incomingCall.call.accept();
      set({
        callStatus: 'connected',
        currentCall: {
          callId: incomingCall.callId,
          caller: incomingCall.caller,
          startTime: new Date().toISOString(),
          call: incomingCall.call,
          isOnHold: false,
          isMuted: false
        },
        incomingCall: null
      });
    } catch (error) {
      handleConnectionError(error, null, set);
    }
  },

  rejectCall: () => {
    const { incomingCall } = get();
    if (incomingCall) {
      incomingCall.call.reject();
      set({ incomingCall: null });
    }
  },

  endCall: () => {
    const { currentCall } = get();
    if (currentCall) {
      currentCall.call.disconnect();
    }
  },

  toggleMute: () => {
    const { currentCall } = get();
    if (currentCall?.call) {
      try {
        if (currentCall.call.isMuted()) {
          currentCall.call.mute(false);
        } else {
          currentCall.call.mute(true);
        }
        set({
          currentCall: {
            ...currentCall,
            isMuted: !currentCall.isMuted
          }
        });
      } catch (error) {
        handleConnectionError(error, null, set);
      }
    }
  },

  toggleHold: () => {
    const { currentCall } = get();
    if (currentCall?.call) {
      try {
        const isOnHold = !currentCall.isOnHold;

        if (isOnHold) {
          currentCall.call.mute(true);
        } else {
          currentCall.call.mute(false);
        }

        set({
          currentCall: { ...currentCall, isOnHold },
          callStatus: isOnHold ? 'hold' : 'connected'
        });
      } catch (error) {
        handleConnectionError(error, null, set);
      }
    }
  },

  sendDigit: (digit: string) => {
    const { currentCall } = get();
    if (currentCall?.call) {
      try {
        currentCall.call.sendDigits(digit);
      } catch (error) {
        handleConnectionError(error, null, set);
      }
    }
  },

  sendDigits: (digits: string) => {
    const { currentCall } = get();
    if (currentCall?.call) {
      try {
        currentCall.call.sendDigits(digits);
      } catch (error) {
        handleConnectionError(error, null, set);
      }
    }
  },

  clearError: () => set({ error: null })
}));

export default useCallStore;