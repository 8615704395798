import React, { useState, useRef, FormEvent, KeyboardEvent } from 'react';
import { Loader2, ArrowRight, ChevronLeft } from 'lucide-react';
import useAuthStore from './store';

interface OTPVerificationProps {
    onNext: () => void;
    onBack: () => void;
    onError: (message: string) => void;
}

const OTPVerification: React.FC<OTPVerificationProps> = ({ onNext, onBack, onError }) => {
    const { verifyOTP } = useAuthStore();
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [otpError, setOtpError] = useState('');
    const inputs = useRef<(HTMLInputElement | null)[]>([]);

    const email = useAuthStore(state => state.email);

    const handleChange = (element: HTMLInputElement, index: number): void => {
        if (isNaN(Number(element.value))) return;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
        setOtpError('');

        if (element.value && index < 5) {
            inputs.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputs.current[index - 1]?.focus();
        }
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setOtpError('');
        onError('');

        const otpValue = otp.join('');
        if (otpValue.length !== 6) {
            setOtpError('Please enter the complete code');
            return;
        }

        setLoading(true);
        try {
            const result = await verifyOTP(otpValue);
            if (result.success) {
                onNext();
            } else {
                setOtpError('Invalid verification code');
            }
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'Failed to verify code';
            setOtpError(errorMessage);
            onError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const handleResend = async () => {
        try {
            setLoading(true);
            if (email) {
                await useAuthStore.getState().signInWithEmail(email);
            }
            setOtp(['', '', '', '', '', '']);
            setOtpError('');
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'Failed to resend code';
            setOtpError(errorMessage);
            onError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-white rounded-xl shadow-lg p-8 w-full max-w-md">
            <button
                onClick={onBack}
                className="text-gray-500 hover:text-gray-700 flex items-center gap-1 mb-6"
            >
                <ChevronLeft className="h-4 w-4" /> Back
            </button>

            <div className="space-y-6">
                <div className="space-y-2">
                    <h1 className="text-2xl font-semibold">Enter verification code</h1>
                    <p className="text-sm text-gray-500">
                        We&apos;ve sent a code to {email}
                    </p>
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="space-y-4">
                        <div className="flex justify-center space-x-3">
                            {otp.map((digit, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength={1}
                                    ref={el => inputs.current[index] = el}
                                    value={digit}
                                    onChange={e => handleChange(e.target, index)}
                                    onKeyDown={e => handleKeyDown(e, index)}
                                    className={`w-12 h-12 text-center text-xl font-semibold rounded-lg border-2
                    focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent
                    ${otpError ? 'border-red-300' : digit ? 'border-purple-500' : 'border-gray-200'}`}
                                    disabled={loading}
                                />
                            ))}
                        </div>
                        {otpError && <p className="text-sm text-red-500 text-center">{otpError}</p>}
                    </div>

                    <button
                        type="submit"
                        className={`w-full h-11 rounded-lg font-medium transition-all flex items-center 
              justify-center gap-2 bg-purple-600 hover:bg-purple-700 text-white
              ${loading ? 'opacity-70 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        {loading ? (
                            <Loader2 className="animate-spin h-4 w-4" />
                        ) : (
                            <>Verify Code <ArrowRight className="h-4 w-4" /></>
                        )}
                    </button>
                </form>

                <p className="text-center text-sm text-gray-500">
                    Didn&apos;t receive the code?{' '}
                    <button
                        onClick={handleResend}
                        className="text-purple-600 hover:text-purple-700 font-medium"
                        disabled={loading}
                    >
                        Resend
                    </button>
                </p>
            </div>
        </div>
    );
};

export default OTPVerification;