import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown, Search } from 'lucide-react';
import { CountrySelectorProps } from '../../types';
import { COUNTRIES } from '../../constants';

const CountrySelector: React.FC<CountrySelectorProps> = ({
    selectedCountry,
    onSelectCountry,
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const searchInputRef = useRef<HTMLInputElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const filteredCountries = COUNTRIES.filter(country =>
        country.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        country.code.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        if (isDropdownOpen && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [isDropdownOpen]);

    return (
        <div className="md:relative w-full sm:w-auto" ref={dropdownRef}>
            <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="w-full sm:w-auto h-full px-2 sm:px-3 py-3 sm:py-4 bg-white rounded-lg flex items-center justify-between sm:justify-start gap-2 hover:bg-gray-50 min-w-[100px] sm:min-w-[120px]"
            >
                <div className="flex items-center gap-2">
                    <span className="text-lg sm:text-xl">{selectedCountry.flag}</span>
                    <span className="text-sm font-medium">{selectedCountry.code}</span>
                </div>
                <ChevronDown size={16} className="text-gray-400 sm:ml-1" />
            </button>

            {isDropdownOpen && (
                <div className="absolute z-10 mt-1 w-full sm:w-64 bg-white rounded-lg shadow-lg max-h-[80vh] sm:max-h-96 overflow-hidden left-0">
                    <div className="p-2 border-b">
                        <div className="relative">
                            <Search 
                                size={16} 
                                className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" 
                            />
                            <input
                                ref={searchInputRef}
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search countries..."
                                className="w-full pl-8 pr-4 py-2 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                    </div>
                    
                    <div className="overflow-y-auto max-h-[60vh] sm:max-h-72">
                        {filteredCountries.length > 0 ? (
                            filteredCountries.map((country) => (
                                <button
                                    key={country.code}
                                    className="w-full px-3 sm:px-4 py-2.5 sm:py-2 text-left hover:bg-gray-100 flex items-center gap-2"
                                    onClick={() => {
                                        onSelectCountry(country);
                                        setIsDropdownOpen(false);
                                        setSearchQuery('');
                                    }}
                                >
                                    <span className="text-lg sm:text-xl">{country.flag}</span>
                                    <div className="flex flex-col">
                                        <span className="text-sm font-medium line-clamp-1">
                                            {country.name}
                                        </span>
                                        <span className="text-xs text-gray-500">
                                            {country.code}
                                        </span>
                                    </div>
                                </button>
                            ))
                        ) : (
                            <div className="px-4 py-3 text-sm text-gray-500 text-center">
                                No countries found
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CountrySelector;