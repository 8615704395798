import { Phone, PhoneIncoming, PhoneOutgoing, PhoneMissed, MessageSquare, Clock } from 'lucide-react';
import { formatDistanceToNow, format } from 'date-fns';
import useCallLogStore from './store';

const StatusIcon = ({ status, direction }: { status: string; direction: 'IN' | 'OUT' }) => {
    const baseClasses = "w-6 h-6 sm:w-8 sm:h-8 rounded-full flex items-center justify-center";
    
    switch (status) {
        case 'missed':
            return (
                <div className={`${baseClasses} bg-red-100`}>
                    <PhoneMissed className="w-3 h-3 sm:w-4 sm:h-4 text-red-500" />
                </div>
            );
        case 'completed':
            return (
                <div className={`${baseClasses} ${direction === 'IN' ? 'bg-green-100' : 'bg-blue-100'}`}>
                    {direction === 'IN' ?
                        <PhoneIncoming className="w-3 h-3 sm:w-4 sm:h-4 text-green-600" /> :
                        <PhoneOutgoing className="w-3 h-3 sm:w-4 sm:h-4 text-blue-600" />}
                </div>
            );
        case 'ongoing':
            return (
                <div className={`${baseClasses} bg-yellow-100`}>
                    <Phone className="w-3 h-3 sm:w-4 sm:h-4 text-yellow-600" />
                </div>
            );
        default:
            return (
                <div className={`${baseClasses} bg-gray-100`}>
                    <Phone className="w-3 h-3 sm:w-4 sm:h-4 text-gray-500" />
                </div>
            );
    }
};

const CallTimeline = () => {
    const { relatedActivities } = useCallLogStore();

    if (relatedActivities.length === 0) {
        return null;
    }

    return (
        <div className="p-2 sm:p-4 text-left max-w-3xl mx-auto">
            <div className="mb-4">
                <h3 className="text-base sm:text-lg font-semibold text-gray-900">Recent Activity</h3>
            </div>

            {/* Timeline container */}
            <div className="relative">
                {/* Continuous vertical line */}
                <div 
                    className="absolute left-3 sm:left-4 top-0 bottom-0 w-0.5 bg-gray-200"
                    style={{ top: '24px', height: 'calc(100% - 48px)' }} 
                />

                <div className="space-y-6 sm:space-y-12">
                    {relatedActivities.map((call) => (
                        <div key={call.id} className="relative flex items-start group">
                            {/* Status Icon */}
                            <div className="relative z-10">
                                <StatusIcon status={call.status} direction={call.direction} />
                            </div>

                            {/* Content Card */}
                            <div className="ml-2 sm:ml-4 flex-grow bg-white rounded-lg shadow-sm border border-gray-100 p-3 sm:p-4">
                                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start">
                                    <div>
                                        <span className="text-sm sm:text-base font-medium text-gray-900">
                                            {call.direction === 'IN' ? 'Incoming call' : 'Outgoing call'}
                                        </span>
                                        <div className="text-xs sm:text-sm text-gray-500 mt-1">
                                            {call.startTime && `${formatDistanceToNow(new Date((call.startTime as any).toDate ? (call.startTime as any).toDate() : call.startTime), { addSuffix: true })} at ${format(new Date((call.startTime as any).toDate ? (call.startTime as any).toDate() : call.startTime), 'p')}`}
                                        </div>
                                    </div>

                                    {call.status === 'ongoing' && (
                                        <div className="flex items-center text-xs sm:text-sm text-green-600 mt-2 sm:mt-0">
                                            <Clock className="w-3 h-3 sm:w-4 sm:h-4 mr-1" />
                                            In progress...
                                        </div>
                                    )}
                                </div>

                                {/* Call details */}
                                <div className="mt-2 text-xs sm:text-sm">
                                    {call.status === 'missed' ? (
                                        <div className="text-red-500 font-medium">Unanswered</div>
                                    ) : (
                                        <div className="text-gray-600">
                                            {call.direction === 'IN' ? (
                                                <span>Answered by {call.answeredByName || 'Unknown'}</span>
                                            ) : (
                                                <span>Called by {call.answeredByName || 'Unknown'}</span>
                                            )}
                                            {call.status === 'completed' && (
                                                <span className="ml-2 text-gray-400">• {call.duration}s</span>
                                            )}
                                        </div>
                                    )}
                                </div>

                                {/* Notes section */}
                                {call.note && (
                                    <div className="mt-3 bg-gray-50 rounded-md p-2 sm:p-3">
                                        <div className="flex items-center space-x-2 mb-1">
                                            <MessageSquare className="w-3 h-3 sm:w-4 sm:h-4 text-gray-400" />
                                            <span className="text-xs sm:text-sm font-medium text-gray-600">Notes</span>
                                        </div>
                                        <div className="text-xs sm:text-sm text-gray-600">
                                            {call.note}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CallTimeline;