import { create } from 'zustand';
import { db } from '../../config/fconfig';
import {
    doc,
    getDoc,
    updateDoc,
    collection,
    query,
    where,
    getDocs,
    onSnapshot,
    orderBy,
    Unsubscribe,
    addDoc
} from 'firebase/firestore';
import { ITag } from '../blocks/tag/store';
import { IActivity } from '../activity/store';
import useAuthStore from '../auth/store';
import { useToastStore } from '../toast/store';

export interface IContactDetails {
    totalCalls: string;
    avgCallTime: string;
    tags: ITag[];
    phone: string;
    email: string;
    address: string;
    name: string;
    dob: string;
    url: string;
    company: string;
    lastActivity?: IActivity;
    id: string;  // Added to store the document ID
}

interface ContactState {
    // State
    userDetails: IContactDetails | null;
    allContacts: IContactDetails[];  // Added to store all users
    isLoading: boolean;
    error: string | null;
    unsubscribe: Unsubscribe | null;  // Added to handle real-time subscription
    selectedContact: IContactDetails | null;  // Added to store the selected user ID

    // Actions
    fetchContactDetails: (userId: string) => Promise<IContactDetails | null | undefined>;
    updateContactField: (userId: string, field: keyof IContactDetails, value: any) => Promise<void>;
    updateContactDetails: (userId: string, details: Partial<IContactDetails>) => Promise<void>;
    searchContactsByEmail: (email: string) => Promise<IContactDetails[]>;
    fetchAllContacts: () => Promise<void>;
    startRealtimeUpdates: () => void;
    stopRealtimeUpdates: () => void;
    reset: () => void;
    setSelectedContact: (userId: string | null) => void;
    createContact: (phone: string) => Promise<IContactDetails>;
}

// Initial state for user details
const initialContactDetails: Omit<IContactDetails, 'id'> = {
    totalCalls: '',
    avgCallTime: '',
    tags: [],
    phone: '',
    email: '',
    address: '',
    name: '',
    dob: '',
    url: '',
    company: ''
};


// Create the store
const useContactStore = create<ContactState>((set, get) => ({
    // Initial state
    userDetails: null,
    allContacts: [],
    isLoading: false,
    error: null,
    unsubscribe: null,
    selectedContact: null,
    setSelectedContact: (userId: string | null) => set({ selectedContact: userId ? get().allContacts.find(u => u.id === userId) : null }),

    createContact: async (phone: string) => {
        set({ isLoading: true, error: null });

        try {
            // First check if user with phone already exists
            const usersRef = collection(db, `ORG/${useAuthStore.getState().getOrgId()}/CONTACT`);
            const q = query(usersRef, where('phone', '==', phone));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                throw new Error('Contact with this phone number already exists');
            }

            // Create new user with template data
            const newContactData: Omit<IContactDetails, 'id'> = {
                ...initialContactDetails,
                phone,
            };

            // Add the new user to Firebase
            const docRef = await addDoc(usersRef, newContactData);

            // Get the newly created user data with ID
            const newContact = {
                ...newContactData,
                id: docRef.id
            } as IContactDetails;

            // Update local state
            set(state => ({
                allContacts: [...state.allContacts, newContact],
                selectedContact: newContact,
                isLoading: false
            }));

            return newContact;

        } catch (error) {
            set({
                error: error instanceof Error ? error.message : 'Failed to create user',
                isLoading: false
            });
            throw error;
        }
    },

    // Fetch user details from Firebase
    fetchContactDetails: async (userId: string): Promise<IContactDetails | null | undefined> => {
        set({ isLoading: true, error: null });
        try {
            if (!userId || userId === "") {
                const initialWithId = { ...initialContactDetails, id: '' };
                set({
                    userDetails: initialWithId,
                    isLoading: false
                });
                return initialWithId;
            }

            const userDoc = await getDoc(doc(db, `ORG/${useAuthStore.getState().getOrgId()}/CONTACT`, userId));

            if (userDoc.exists()) {
                const userData = {
                    ...userDoc.data(),
                    id: userDoc.id
                } as IContactDetails;

                set({
                    userDetails: userData,
                    isLoading: false
                });

                return userData;
            } else {
                // If user doesn't exist, create with initial state
                const userRef = doc(db, `ORG/${useAuthStore.getState().getOrgId()}/CONTACT`, userId);
                await updateDoc(userRef, initialContactDetails as { [x: string]: any });
                const initialWithId = { ...initialContactDetails, id: userId };
                set({
                    userDetails: initialWithId,
                    isLoading: false
                });
                return initialWithId;
            }
        } catch (error) {
            set({
                error: error instanceof Error ? error.message : 'Failed to fetch user details',
                isLoading: false
            });
            return;
        }
    },

    // Fetch all users with ordering
    fetchAllContacts: async () => {
        set({ isLoading: true, error: null });
        try {
            const usersRef = collection(db, `ORG/${useAuthStore.getState().getOrgId()}/CONTACT`);
            const q = query(
                usersRef,
                orderBy('lastActivity.startTime', 'desc'),
                orderBy('id')
            );

            const querySnapshot = await getDocs(q);
            const users: IContactDetails[] = [];


            querySnapshot.forEach((doc) => {
                users.push({
                    ...doc.data(),
                    id: doc.id
                } as IContactDetails);
            });

            set({
                allContacts: users,
                isLoading: false
            });
        } catch (error) {
            set({
                error: error instanceof Error ? error.message : 'Failed to fetch users',
                isLoading: false
            });
        }
    },

    // Start real-time updates
    startRealtimeUpdates: () => {
        const current = get().unsubscribe;
        if (current) {
            current();
        }

        const usersRef = collection(db, `ORG/${useAuthStore.getState().getOrgId()}/CONTACT`);
        const q = query(
            usersRef,
            orderBy('lastActivity.startTime', 'desc'),
            orderBy('id')
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const users: IContactDetails[] = [];
            snapshot.forEach((doc) => {
                users.push({
                    ...doc.data(),
                    id: doc.id
                } as IContactDetails);
            });
            set({ allContacts: users });
        }, (error) => {
            set({ error: error.message });
        });

        set({ unsubscribe });
    },

    // Stop real-time updates
    stopRealtimeUpdates: () => {
        const current = get().unsubscribe;
        if (current) {
            current();
            set({ unsubscribe: null });
        }
    },

    // Update a single field
    updateContactField: async (userId: string, field: keyof IContactDetails, value: any) => {
        set({ isLoading: true, error: null });
        try {
            if (!userId || userId === "") {
                const initialWithId = { ...initialContactDetails, id: '' };
                set({
                    userDetails: initialWithId,
                    isLoading: false
                });
                return;
            }

            const userRef = doc(db, `ORG/${useAuthStore.getState().getOrgId()}/CONTACT`, userId);
            await updateDoc(userRef, { [field]: value });

            useToastStore.getState().addToast({
                variant: 'success',
                message: `${field.charAt(0).toUpperCase() + field.slice(1)} updated successfully`
            });

            // Update local state
            set(state => ({
                userDetails: state.userDetails ? {
                    ...state.userDetails,
                    [field]: value
                } : null,
                selectedContact: state.selectedContact && state.selectedContact.id === userId ? {
                    ...state.selectedContact,
                    [field]: value
                } : state.selectedContact,
                isLoading: false
            }));


        } catch (error) {
            set({
                error: error instanceof Error ? error.message : 'Failed to update user field',
                isLoading: false
            });
        }
    },

    // Update multiple fields at once
    updateContactDetails: async (userId: string, details: Partial<IContactDetails>) => {
        set({ isLoading: true, error: null });
        if (!userId || userId === "") {
            const initialWithId = { ...initialContactDetails, id: '' };
            set({
                userDetails: initialWithId,
                isLoading: false
            });
            return;
        }

        try {
            const userRef = doc(db, `ORG/${useAuthStore.getState().getOrgId()}/CONTACT`, userId);
            await updateDoc(userRef, details);

            // Update local state
            set(state => ({
                userDetails: state.userDetails ? {
                    ...state.userDetails,
                    ...details
                } : null,
                selectedContact: state.selectedContact && state.selectedContact.id === userId ? {
                    ...state.selectedContact,
                    ...details
                } : state.selectedContact,
                isLoading: false
            }));
        } catch (error) {
            set({
                error: error instanceof Error ? error.message : 'Failed to update user details',
                isLoading: false
            });
        }
    },

    // Search users by email
    searchContactsByEmail: async (email: string) => {
        set({ isLoading: true, error: null });

        if (!email || email === "") {
            return [];
        }

        try {
            const usersRef = collection(db, `ORG/${useAuthStore.getState().getOrgId()}/CONTACT`);
            const q = query(usersRef, where('email', '==', email));
            const querySnapshot = await getDocs(q);

            const users: IContactDetails[] = [];
            querySnapshot.forEach((doc) => {
                users.push({
                    ...doc.data(),
                    id: doc.id
                } as IContactDetails);
            });

            set({ isLoading: false });
            return users;
        } catch (error) {
            set({
                error: error instanceof Error ? error.message : 'Failed to search users',
                isLoading: false
            });
            return [];
        }
    },

    // Reset store to initial state
    reset: () => {
        const current = get().unsubscribe;
        if (current) {
            current();
        }

        set({
            userDetails: null,
            allContacts: [],
            isLoading: false,
            error: null,
            unsubscribe: null
        });
    }
}));

export default useContactStore;