import React from 'react';
import { DialPadProps } from '../types';

const DialPad: React.FC<DialPadProps> = ({ onNumberClick }) => (
  <div className="grid grid-cols-3 gap-3 w-full mx-auto justify-center items-center">
    {[1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'].map((number) => (
      <button
        key={number}
        onClick={() => onNumberClick(number.toString())}
        className="h-24 w-24 rounded-full bg-white hover:bg-gray-200 text-xlg font-medium flex items-center justify-center shadow-sm transition-colors mx-auto"
      >
        {number}
      </button>
    ))}
  </div>
);

export default DialPad;