import { create } from 'zustand';
import { db } from '../../config/fconfig';
import {
    collection,
    getDocs,
    query,
    orderBy,
    limit,
    where,
    DocumentData,
    QueryDocumentSnapshot,
    Timestamp,
    addDoc,
    serverTimestamp,
    updateDoc,
    doc,
    getDoc,
    onSnapshot,
    Unsubscribe
} from 'firebase/firestore';
import { useToastStore } from '@/components/toast/store';
import useUserStore from '../contacts/store';
import useAuthStore from '../auth/store';
import useCallStore from '@/config/twilio';
import useContactStore from '../contacts/store';

export interface IActivity {
    id?: string;
    duration?: number | '';
    status: 'completed' | 'missed' | 'ongoing' | 'answered';
    to: string;
    from: string;
    startTime: Date | Timestamp;
    endTime: Date | Timestamp;
    direction: 'IN' | 'OUT';
    answeredBy?: string;
    answeredByName?: string;
    answeredByProfilePic?: string;
    note: string;
    contactId: string;
    name?: string;
}

interface CreateActivityParams {
    phoneNumber: string;
    direction: 'IN' | 'OUT';
    duration?: number | string;
    note?: string;
    status: 'completed' | 'missed' | 'ongoing' | 'answered';
    answeredBy?: string;
}

interface ActivityState {
    relatedActivities: IActivity[];
    activeActivity: IActivity | null;
    isLoading: boolean;
    error: string | null;
    fetchActivitiesByContact: () => Promise<void>;
    createCallActivity: (params: CreateActivityParams) => Promise<string | void>;
    setActiveActivity: (activity: IActivity | null) => void;
    updateActiveActivity: (updates: Partial<IActivity>) => Promise<void>;
    clearError: () => void;
    subscribeToActiveActivity: () => void;
    unsubscribeFromActiveActivity: () => void;
    _unsubscribe: Unsubscribe | null;
}

// Validation functions
const validatePhoneNumber = (phoneNumber: string): boolean => {
    const phoneRegex = /^\d{10,15}$/;
    return phoneRegex.test(phoneNumber.replace(/\D/g, ''));
};

const validateDuration = (duration: number | string | undefined): boolean => {
    if (duration === undefined) return true;
    if (typeof duration === 'string') {
        return !isNaN(Number(duration));
    }
    return duration >= 0;
};

const enrichActivityWithAnswererDetails = async (activity: IActivity): Promise<IActivity> => {
    if (!activity.answeredBy) return activity;

    try {
        const userDoc = await getDoc(doc(db, `USER/${activity.answeredBy}`));
        if (userDoc.exists()) {
            const userData = userDoc.data();
            return {
                ...activity,
                answeredByName: userData.displayName || '',
                answeredByProfilePic: userData.photoUrl || ''
            };
        }
        return activity;
    } catch (error) {
        console.error('Error fetching answerer details:', error);
        return activity;
    }
};


const convertFirestoreDoc = async (
    doc: QueryDocumentSnapshot<DocumentData>
): Promise<IActivity> => {
    const data = doc.data();
    const baseActivity = {
        id: doc.id,
        duration: data.duration === 0 ? '' : data.duration,
        status: data.status || 'completed',
        to: data.to,
        from: data.from,
        startTime: data.startTime,
        endTime: data.endTime,
        direction: data.direction,
        answeredBy: data.answeredBy,
        note: data.note || '',
        contactId: data.contactId
    };

    return enrichActivityWithAnswererDetails(baseActivity);
};

const useActivityStore = create<ActivityState>((set, get) => ({
    relatedActivities: [],
    activeActivity: null,
    isLoading: false,
    error: null,
    _unsubscribe: null,

    setActiveActivity: (activity: IActivity | null) => {
        const { unsubscribeFromActiveActivity, subscribeToActiveActivity } = get();

        // Clean up existing subscription
        unsubscribeFromActiveActivity();

        set({ activeActivity: activity });

        // Set up new subscription if we have an activity
        if (activity) {
            subscribeToActiveActivity();
        }
    },

    updateActiveActivity: async (updates: Partial<IActivity>) => {
        const { activeActivity } = get();
        if (!activeActivity?.id) return;

        try {
            const activityRef = doc(
                db,
                `${`ORG/${useAuthStore.getState().getOrgId()}`}/CONTACT/${activeActivity.contactId}/ACTIVITY/${activeActivity.id}`
            );

            await updateDoc(activityRef, {
                ...updates,
                updatedAt: serverTimestamp()
            });

            const updatedActivity = {
                ...activeActivity,
                ...updates
            };

            set({ activeActivity: updatedActivity });
        } catch (error) {
            set({
                error: error instanceof Error ? error.message : 'Failed to update active activity'
            });
        }
    },

    createCallActivity: async (params: CreateActivityParams): Promise<string | void> => {
        try {
            if (!get().activeActivity) {
                useToastStore.getState().addToast({
                    message: 'Initiating call...',
                    variant: 'error'
                })

                const preliminaryActivity: IActivity = {
                    duration: 0,
                    status: params.status,
                    to: params.phoneNumber,
                    from: params.phoneNumber,
                    startTime: new Date(),
                    endTime: new Date(),
                    direction: params.direction,
                    note: params.note || '',
                    contactId: '',
                    answeredBy: params.answeredBy
                };

                set({ activeActivity: preliminaryActivity });

                // Show initial toast
                useToastStore.getState().addToast({
                    message: `Initiating call to ${params.phoneNumber}...`,
                    variant: 'info',
                    duration: 5000
                });

                // Validate phone number
                if (!validatePhoneNumber(params.phoneNumber)) {
                    throw new Error('Invalid phone number format');
                }

                // Validate duration if provided
                if (!validateDuration(params.duration)) {
                    throw new Error('Invalid duration value');
                }

                // Find or create contact
                const usersCollection = collection(db, `ORG/${useAuthStore.getState().getOrgId()}/CONTACT`);
                const userQuery = query(
                    usersCollection,
                    where('phone', '==', params.phoneNumber)
                );

                const userSnapshot = await getDocs(userQuery);
                let contactId;

                if (userSnapshot.empty) {
                    contactId = (await useUserStore.getState().createContact(params.phoneNumber)).id;
                } else {
                    contactId = userSnapshot.docs[0].id;
                }

                useContactStore.getState().setSelectedContact(contactId);

                // Update preliminary activity with contact ID
                preliminaryActivity.contactId = contactId;
                set({ activeActivity: preliminaryActivity });

                // Prepare activity data
                const activityData = {
                    direction: params.direction,
                    duration: params.duration || '',
                    note: params.note || '',
                    status: params.status,
                    answeredBy: useAuthStore.getState().user?.uid || '',
                    from: params.phoneNumber,
                    to: params.phoneNumber,
                    startTime: serverTimestamp(),
                    endTime: serverTimestamp(),
                    contactId,
                    type: 'ACTIVITY',
                    createdAt: serverTimestamp(),
                    updatedAt: serverTimestamp()
                };

                // Create the activity document
                const activityCollection = collection(
                    db,
                    `${`ORG/${useAuthStore.getState().getOrgId()}`}/CONTACT/${contactId}/ACTIVITY`
                );

                const docRef = await addDoc(activityCollection, activityData);

                get().setActiveActivity({
                    ...preliminaryActivity,
                    id: docRef.id
                });

                // Initiate the actual call
                useCallStore.getState().makeCall({
                    id: `${useAuthStore.getState().getOrgId()}-${contactId}-${docRef.id}`,
                    to: params.phoneNumber,
                    customData: {
                        activityId: docRef.id,
                        contactId,
                        direction: params.direction,
                        orgId: useAuthStore.getState().getOrgId()
                    }
                });

                // Update contact's last activity
                updateDoc(doc(db, `${`ORG/${useAuthStore.getState().getOrgId()}`}/CONTACT/${contactId}`), {
                    lastActivity: activityData,
                    id: contactId
                });

                get().fetchActivitiesByContact();

                return docRef.id;
            } else {
                useToastStore.getState().addToast({
                    message: 'Cannot start another calllog while one is in progress',
                    variant: 'error'
                })
            }

        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Failed to create activity';
            set({ error: errorMessage });

            useToastStore.getState().addToast({
                message: errorMessage,
                variant: 'error',
                duration: 7000
            });

            // Clear active activity on error
            set({ activeActivity: null });

            throw error;
        }
    },

    fetchActivitiesByContact: async () => {
        const user = useUserStore.getState().selectedContact;

        if (!user) {
            set({ error: 'No activity selected', isLoading: false });
            return;
        }

        set({ isLoading: true, error: null });
        try {
            const activitiesCollection = collection(
                db,
                `${`ORG/${useAuthStore.getState().getOrgId()}`}/CONTACT/${user.id}/ACTIVITY`
            );

            const snapshot = await getDocs(query(
                activitiesCollection,
                orderBy('startTime', 'desc'),
                limit(50)
            ));

            const activities = await Promise.all(snapshot.docs.map(convertFirestoreDoc));

            const allActivities = activities
                .sort((a, b) => {
                    const startTimeA = a.startTime instanceof Timestamp ? a.startTime.toDate() : a.startTime;
                    const startTimeB = b.startTime instanceof Timestamp ? b.startTime.toDate() : b.startTime;
                    return new Date(startTimeB).getTime() - new Date(startTimeA).getTime();
                })
                .filter((activity, index, self) =>
                    index === self.findIndex(a => a.id === activity.id)
                );

            set({ relatedActivities: allActivities, isLoading: false });
        } catch (error) {
            set({
                error: error instanceof Error ? error.message : 'Failed to fetch related activities',
                isLoading: false
            });
        }
    },

    clearError: () => set({ error: null }),

    subscribeToActiveActivity: () => {
        const { activeActivity, _unsubscribe } = get();

        // Clean up existing subscription if any
        if (_unsubscribe) {
            _unsubscribe();
        }

        // Only subscribe if we have an active activity
        if (activeActivity?.id && activeActivity.contactId) {
            const activityRef = doc(
                db,
                `ORG/${useAuthStore.getState().getOrgId()}/CONTACT/${activeActivity.contactId}/ACTIVITY/${activeActivity.id}`
            );

            const unsubscribe = onSnapshot(activityRef, async (snapshot) => {
                if (snapshot.exists() && snapshot.id === get().activeActivity?.id) {
                    const data = snapshot.data();
                    const enrichedActivity = {
                        ...get().activeActivity,
                        duration: data.duration === 0 ? '' : data.duration,
                        startTime: data.startTime,
                        endTime: data.endTime,
                        status: data.status || 'completed',
                    } as IActivity;

                    const updatedActivities = get().relatedActivities.map(activity =>
                        activity.id === enrichedActivity.id ? enrichedActivity : activity
                    );

                
                    set({ relatedActivities: updatedActivities });

                    if (data.status === 'completed') {
                        useToastStore.getState().addToast({
                            message: `Call to ${enrichedActivity.to} completed`,
                            variant: 'success'
                        });
                        get().unsubscribeFromActiveActivity();
                        set({ activeActivity: null });
                        return;
                    }
                    set({ activeActivity: enrichedActivity });
                }
            }, (error) => {
                set({ error: `Real-time update error: ${error.message}` });
            });

            // Store the unsubscribe function
            set({ _unsubscribe: unsubscribe });
        }
    },

    unsubscribeFromActiveActivity: () => {
        const { _unsubscribe } = get();
        if (_unsubscribe) {
            _unsubscribe();
            set({ _unsubscribe: null });
        }
    },
}));

export default useActivityStore;