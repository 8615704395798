import { create } from 'zustand';
import { Toast } from './types';

interface ToastStore {
  toasts: Toast[];
  addToast: (toast: Omit<Toast, 'id'>) => void;
  removeToast: (id: number) => void;
}

export const useToastStore = create<ToastStore>((set) => ({
  toasts: [],
  addToast: (toast) =>
    set(() => ({
      toasts: [{ ...toast, id: Date.now() }],
    })),
  removeToast: () =>
    set(() => ({
      toasts: []
    })),
}));