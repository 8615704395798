import React from 'react';
import { Check, ArrowRight } from 'lucide-react';

interface SuccessPageProps {
    onReset: () => void;
}

const SuccessPage: React.FC<SuccessPageProps> = ({ onReset }) => (
    <div className="bg-white rounded-xl shadow-lg p-8 w-full max-w-md">
        <div className="text-center space-y-6">
            <div className="w-16 h-16 bg-green-100 rounded-full mx-auto flex items-center justify-center">
                <Check className="h-8 w-8 text-green-600" />
            </div>

            <div className="space-y-2">
                <h2 className="text-2xl font-semibold">Welcome aboard!</h2>
                <p className="text-sm text-gray-500">
                    Your account has been successfully created and verified
                </p>
            </div>

            <button
                onClick={onReset}
                className="w-full h-11 rounded-lg font-medium transition-all flex items-center 
          justify-center gap-2 bg-purple-600 hover:bg-purple-700 text-white"
            >
                Get Started <ArrowRight className="h-4 w-4" />
            </button>
        </div>
    </div>
);

export default SuccessPage;