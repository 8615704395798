import React from 'react';
import PhoneCallInterface from '../components/dialer/incall';
import PhoneDialer from '../components/dialer';
import ContactUI from '../components/contacts/view';
import ContactList from '../components/contacts';
import useContactStore from '../components/contacts/store';

const Home: React.FC = () => {
    const selectedContact = useContactStore(state => state.selectedContact);
  
    return (
        <div className="flex flex-row relative h-screen">
            {/* UserList container */}
            <div className={`
                bg-[#F8F9FE] relative
                md:min-w-[30%]
                ${selectedContact ? 
                    'hidden md:block' : // Hide on mobile when contact selected
                    'w-full md:w-auto' // Full width on mobile when no contact
                }
            `}>
                <div className="absolute bottom-0 w-full z-10">
                    <PhoneCallInterface />
                </div>
                <ContactList />
            </div>

            {/* Main content area */}
            <div className={`
                flex  h-screen
                ${selectedContact ? 
                    'w-full md:flex-grow' : // Full width on mobile when contact selected
                    'hidden md:flex md:flex-grow' // Hidden on mobile when no contact
                }
                ${!selectedContact ? 
                    'items-center justify-center' : 
                    'md:pl-8'
                }
            `}>
                
                {selectedContact ? <ContactUI /> : <PhoneDialer />}
            </div>
        </div>
    );
};

export default Home;