import React, { useEffect } from 'react';
import { Phone, MicOff, Mic, Pause, Play, Grid } from 'lucide-react';
import ContactAvatar from '../blocks/avatar';
import useCallStore from '@/config/twilio';
import useUserStore from '../contacts/store';

const PhoneCallInterface: React.FC = () => {
    const { 
        currentCall,
        callStatus,
        incomingCall,
        toggleMute,
        toggleHold,
        sendDigit,
        endCall,
        answerCall
    } = useCallStore();

    const selectedContact = useUserStore((state) => state.selectedContact);
    
    const [showKeypad, setShowKeypad] = React.useState(false);
    const [elapsedTime, setElapsedTime] = React.useState(0);

    // Start timer when call connects
    useEffect(() => {
        let timer: ReturnType<typeof setInterval>;
        if (callStatus === 'connected' && currentCall) {
            const startTime = new Date(currentCall.startTime).getTime();
            timer = setInterval(() => {
                const now = new Date().getTime();
                setElapsedTime(Math.floor((now - startTime) / 1000));
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [callStatus, currentCall]);

    // If there's no call or the call is disconnected, don't render
    if (!currentCall && !incomingCall || callStatus === 'disconnected') {
        return null;
    }

    const formatTime = (totalSeconds: number): string => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        if (hours > 0) {
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleKeypadClick = (digit: string) => {
        sendDigit(digit);
    };

    const handleCallAction = () => {
        if (incomingCall) {
            answerCall();
        } else {
            endCall();
        }
    };

    const getStatusDisplay = () => {
        switch (callStatus) {
            case 'connecting':
                return 'Connecting...';
            case 'connected':
                return formatTime(elapsedTime);
            case 'hold':
                return 'On Hold';
            case 'reconnecting':
                return 'Reconnecting...';
            case 'error':
                return 'Call Error';
            default:
                return incomingCall ? 'Incoming Call' : 'Dialing...';
        }
    };

    const getCallerInfo = () => {
        if (incomingCall) {
            return incomingCall.caller;
        }
        return currentCall?.recipient || selectedContact?.name || 'Unknown';
    };

    return (
        <div className={`bg-gray-900 flex flex-col gap-8 p-6 m-10 rounded-lg ${showKeypad ? 'bg-gray-800' : ''}`}>
            {/* Header */}
            <div className="flex items-center gap-8">
                <div className="flex items-center flex-1">
                    <ContactAvatar input={getCallerInfo()} size="md" />
                    <div className="ml-3">
                        <div className="text-white text-lg">{getCallerInfo()}</div>
                        <div className="text-gray-400 text-sm flex items-center">
                            <div className={`w-2 h-2 rounded-full mr-2 ${
                                callStatus === 'connected' ? 'bg-green-500' :
                                callStatus === 'hold' ? 'bg-yellow-500' :
                                'bg-red-500'
                            }`}></div>
                            {getStatusDisplay()}
                        </div>
                    </div>
                </div>
                
                <button 
                    className={`p-2 rounded-full ${incomingCall ? 'bg-green-500 hover:bg-green-600' : 'bg-red-500 hover:bg-red-600'} ml-2`}
                    onClick={handleCallAction}
                >
                    <Phone className="w-5 h-5 text-white" />
                </button>
            </div>

            {/* Call actions */}
            <div className="grid grid-cols-3 gap-4 text-gray-400 text-sm">
                <div className="flex flex-col items-center gap-1">
                    <button 
                        className={`p-3 rounded-full hover:bg-gray-800 transition-colors ${currentCall?.isMuted ? 'bg-red-500/20 text-red-500' : ''}`}
                        onClick={toggleMute}
                    >
                        {currentCall?.isMuted ? <MicOff className="w-6 h-6" /> : <Mic className="w-6 h-6" />}
                    </button>
                    <span>{currentCall?.isMuted ? 'Unmute' : 'Mute'}</span>
                </div>
                <div className="flex flex-col items-center gap-1">
                    <button 
                        className={`p-3 rounded-full hover:bg-gray-800 transition-colors ${currentCall?.isOnHold ? 'bg-yellow-500/20 text-yellow-500' : ''}`}
                        onClick={toggleHold}
                    >
                        {currentCall?.isOnHold ? <Play className="w-6 h-6" /> : <Pause className="w-6 h-6" />}
                    </button>
                    <span>{currentCall?.isOnHold ? 'Resume' : 'Hold'}</span>
                </div>
                <div className="flex flex-col items-center gap-1">
                    <button 
                        className={`p-3 rounded-full hover:bg-gray-800 transition-colors ${showKeypad ? 'bg-blue-500/20 text-blue-500' : ''}`}
                        onClick={() => setShowKeypad(!showKeypad)}
                    >
                        <Grid className="w-6 h-6" />
                    </button>
                    <span>Keypad</span>
                </div>
            </div>

            {/* Keypad */}
            {showKeypad && (
                <div className="grid grid-cols-3 gap-4">
                    {['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'].map((key) => (
                        <button
                            key={key}
                            className="bg-gray-700 text-white py-3 rounded-lg hover:bg-gray-600 text-xl"
                            onClick={() => handleKeypadClick(key)}
                        >
                            {key}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PhoneCallInterface;