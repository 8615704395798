import React, { useState, FormEvent } from 'react';
import { Loader2, ArrowRight, ChevronLeft } from 'lucide-react';
import useAuthStore from './store'; // Adjust the import path as needed

interface UserDetailsPageProps {
    onNext: () => void;
    onBack: () => void;
    onError: (message: string) => void;
}

const UserDetailsPage: React.FC<UserDetailsPageProps> = ({ onNext, onBack, onError }) => {
    const updateUserDetails = useAuthStore(state => state.updateUserDetails);
    const currentUser = useAuthStore(state => state.user);

    // Initialize form with current user's data if available
    const [form, setForm] = useState({
        name: currentUser?.displayName || '',
        phone: currentUser?.phoneNumber || '',
        countryCode: '+1'
    });


    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<{ name?: string; phone?: string }>({});

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const newErrors: { name?: string; phone?: string } = {};

        // Validation
        if (!form.phone) newErrors.phone = 'Phone number is required';
        if (!form.name) newErrors.name = 'Name is required';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setLoading(true);

        try {
            // Format the phone number with country code
            const fullPhoneNumber = `${form.countryCode}${form.phone}`;

            // Map name to displayName when updating
            await updateUserDetails({
                displayName: form.name,  // This maps 'name' to 'displayName'
                phoneNumber: fullPhoneNumber,
            });

            // If successful, move to next step
            onNext();
        } catch (error) {
            // Handle any errors
            const errorMessage = error instanceof Error ? error.message : 'Failed to update user details';
            onError(errorMessage);
            setLoading(false);
        }
    };

    return (
        <div className="bg-white rounded-xl shadow-lg p-8 w-full max-w-md">
            <button
                onClick={onBack}
                className="text-gray-500 hover:text-gray-700 flex items-center gap-1 mb-6"
            >
                <ChevronLeft className="h-4 w-4" /> Back
            </button>

            <div className="space-y-6">
                <div className="space-y-2">
                    <h1 className="text-2xl font-semibold">Complete your profile</h1>
                    <p className="text-sm text-gray-500">Please provide your details to continue</p>
                </div>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700">Full Name</label>
                        <input
                            type="text"
                            value={form.name}
                            onChange={e => {
                                setForm({ ...form, name: e.target.value });
                                setErrors({ ...errors, name: '' });
                            }}
                            className={`w-full h-11 px-4 rounded-lg border ${errors.name ? 'border-red-300' : 'border-gray-200'
                                } focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent`}
                            placeholder="Enter your full name"
                            disabled={loading}
                        />
                        {errors.name && <p className="text-sm text-red-500">{errors.name}</p>}
                    </div>

                    <div className="space-y-2">
                        <label className="text-sm font-medium text-gray-700">Phone Number</label>
                        <div className="flex space-x-2">
                            <select
                                value={form.countryCode}
                                onChange={e => setForm({ ...form, countryCode: e.target.value })}
                                className="h-11 px-3 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-purple-500"
                                disabled={loading}
                            >
                                <option value="+1">+1</option>
                                <option value="+44">+44</option>
                                <option value="+91">+91</option>
                            </select>
                            <input
                                type="tel"
                                value={form.phone}
                                onChange={e => {
                                    setForm({ ...form, phone: e.target.value });
                                    setErrors({ ...errors, phone: '' });
                                }}
                                className={`flex-1 h-11 px-4 rounded-lg border ${errors.phone ? 'border-red-300' : 'border-gray-200'
                                    } focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent`}
                                placeholder="Enter phone number"
                                disabled={loading}
                            />
                        </div>
                        {errors.phone && <p className="text-sm text-red-500">{errors.phone}</p>}
                    </div>

                    <button
                        type="submit"
                        className={`w-full h-11 rounded-lg font-medium transition-all flex items-center 
                            justify-center gap-2 bg-purple-600 hover:bg-purple-700 text-white ${loading ? 'opacity-70 cursor-not-allowed' : ''
                            }`}
                        disabled={loading}
                    >
                        {loading ? (
                            <Loader2 className="animate-spin h-4 w-4" />
                        ) : (
                            <>
                                Complete Setup
                                <ArrowRight className="h-4 w-4" />
                            </>
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default UserDetailsPage;