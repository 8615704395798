interface EnvVariables {
    REACT_APP_FIREBASE_API_KEY: string;
    REACT_APP_FIREBASE_AUTH_DOMAIN: string;
    REACT_APP_FIREBASE_PROJECT_ID: string;
    REACT_APP_FIREBASE_STORAGE_BUCKET: string;
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID: string;
    REACT_APP_FIREBASE_APP_ID: string;
}

function getEnvVar(key: keyof EnvVariables): string {
    const value = process.env[key];

    if (!value) {
        throw new Error(`Missing environment variable: ${key}`);
    }

    return value;
}

export const env = {
    firebase: {
        apiKey: getEnvVar('REACT_APP_FIREBASE_API_KEY'),
        authDomain: getEnvVar('REACT_APP_FIREBASE_AUTH_DOMAIN'),
        projectId: getEnvVar('REACT_APP_FIREBASE_PROJECT_ID'),
        storageBucket: getEnvVar('REACT_APP_FIREBASE_STORAGE_BUCKET'),
        messagingSenderId: getEnvVar('REACT_APP_FIREBASE_MESSAGING_SENDER_ID'),
        appId: getEnvVar('REACT_APP_FIREBASE_APP_ID'),
    }
} as const;
