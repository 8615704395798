import useAuthStore from '../components/auth/store';
import { Navigate, useLocation } from 'react-router-dom';

interface ProtectedRouteProps {
    children: React.ReactNode;
    requireAuth?: boolean;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    children,
    requireAuth = true,
}: ProtectedRouteProps) => {
    const isAuthenticated = useAuthStore(state => state.isAuthenticated);
    const location = useLocation();

    // Simple mobile detection
    const isMobile = window.innerWidth <= 768;

    if (requireAuth && !isAuthenticated) {
        // Save the attempted URL in the state
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (!requireAuth && isAuthenticated) {
        // Check if there's a saved path in the location state
        const savedPath = location.state?.from?.pathname;
        
        // If there's a saved path, use it; otherwise, use the default redirectPath logic
        const redirectPath = savedPath || (isMobile ? '/contacts' : '/');
        
        return <Navigate to={redirectPath} replace />;
    }

    return <>{children}</>;
};