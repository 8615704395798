import React, { ChangeEvent, FormEvent, useEffect } from 'react';
import FormInput from '../blocks/form/input';
import ActionButton from '../blocks/button/action';
import { Info, ChevronDown, ChevronUp, X } from 'lucide-react';
import useCredentialsStore from '@/components/credentials/store';

const COUNTRY_CODES = [
    { code: 'US', dial_code: '+1', name: 'United States', flag: 'us' },
    { code: 'GB', dial_code: '+44', name: 'United Kingdom', flag: 'gb' },
    { code: 'CA', dial_code: '+1', name: 'Canada', flag: 'ca' },
    { code: 'AU', dial_code: '+61', name: 'Australia', flag: 'au' },
    { code: 'DE', dial_code: '+49', name: 'Germany', flag: 'de' },
    { code: 'FR', dial_code: '+33', name: 'France', flag: 'fr' },
];

const Credentials: React.FC = () => {
    const {
        credentials,
        showConfig,
        expandedVideo,
        showVideo,
        hasCredentials,
        setCredentials,
        setShowConfig,
        setExpandedVideo,
        setShowVideo,
        saveToFirestore,
        loadLatestAsset
    } = useCredentialsStore();

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await saveToFirestore();
    };

    useEffect(() => {
        // Load the latest asset when component mounts
        loadLatestAsset(false);
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = e.target.type === 'checkbox' ? (e.target as HTMLInputElement).checked : e.target.value;
        setCredentials({ [e.target.name]: value });
    };

    return (
        // Changed padding and max-width for better mobile display
        <div className="w-full max-w-2xl mx-auto p-4 pt-4 sm:pt-[8vh]">
            <form onSubmit={handleSubmit} className="space-y-6 sm:space-y-8">
                {/* Personal Information Section */}
                <div className="space-y-4 sm:space-y-6">
                    <h2 className="text-lg sm:text-xl font-semibold">
                        {!hasCredentials ? 'New Configuration' : 'Edit Configuration'}
                    </h2>
                    <p className="text-sm sm:text-base text-gray-500">
                        Bring your own Twilio credentials and we will do the rest. Don't worry, all keys are encrypted and stored safely using Google Secrets.
                    </p>

                    {/* Phone Number with Country Code - Modified for mobile */}
                    <div className="space-y-2">
                        <label className="text-gray-700 text-sm sm:text-base">Phone Number</label>
                        {/* Stack on mobile, side by side on desktop */}
                        <div className="flex flex-col sm:flex-row gap-2">
                            <div className="relative w-full sm:w-auto">
                                <select
                                    name="countryCode"
                                    value={credentials.countryCode}
                                    onChange={handleChange}
                                    className="w-full sm:w-auto rounded-lg border border-gray-200 px-4 py-3 pl-12 text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none text-sm sm:text-base"
                                >
                                    {COUNTRY_CODES.map((country) => (
                                        <option key={country.dial_code + country.code} value={country.dial_code}>
                                            {country.dial_code} {country.name}
                                        </option>
                                    ))}
                                </select>
                                <div className="absolute left-3 top-1/2 -translate-y-1/2 w-6 h-4 rounded overflow-hidden">
                                    <img
                                        src={`https://flagcdn.com/32x24/${COUNTRY_CODES.find((country) => country.dial_code === credentials.countryCode)?.flag || 'us'}.png`}
                                        alt={`${credentials.countryCode} flag`}
                                        srcSet={`https://flagcdn.com/56x42/${COUNTRY_CODES.find((country) => country.dial_code === credentials.countryCode)?.flag || 'us'}.png 2x,
                                          https://flagcdn.com/80x60/${COUNTRY_CODES.find((country) => country.dial_code === credentials.countryCode)?.flag || 'us'}.png 3x`}
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                                <div className="absolute right-2 top-1/2 -translate-y-1/2 pointer-events-none">
                                    <ChevronDown className="w-4 h-4 sm:w-5 sm:h-5 text-gray-400" />
                                </div>
                            </div>
                            <FormInput
                                id="phoneNumber"
                                name="phoneNumber"
                                type='number'
                                value={credentials.phoneNumber}
                                onChange={handleChange}
                                placeholder="Enter phone number"
                                className="w-full"
                            />
                        </div>
                    </div>

                    {/* Toggle Switch - Adjusted spacing */}
                    <div className="flex items-center gap-2 sm:gap-3">
                        <button
                            type="button"
                            onClick={() => setCredentials(({ ...credentials, isActive: !credentials.isActive }))}
                            className={`relative inline-flex h-5 sm:h-6 w-10 sm:w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${credentials.isActive ? 'bg-blue-600' : 'bg-gray-200'}`}
                        >
                            <span
                                className={`inline-block h-3 sm:h-4 w-3 sm:w-4 transform rounded-full bg-white transition-transform ${credentials.isActive ? 'translate-x-6' : 'translate-x-1'}`}
                            />
                        </button>
                        <span className="text-xs sm:text-sm font-medium text-gray-700">Active</span>
                    </div>
                </div>

                {/* Configuration Section */}
                <div className="relative">
                    <div className="absolute inset-x-0 top-0 h-px bg-gray-200" />

                    {/* Update Configuration button - Centered on mobile */}
                    {hasCredentials && (
                        <div className="absolute -top-3 sm:-top-4 left-1/2 -translate-x-1/2 w-full sm:w-auto text-center">
                            <button
                                type="button"
                                onClick={() => setShowConfig(!showConfig)}
                                className="inline-flex items-center justify-center gap-2 bg-white px-3 sm:px-4 py-1.5 sm:py-2 text-xs sm:text-sm font-medium text-gray-700 hover:bg-gray-50 rounded-full border w-full sm:w-auto"
                            >
                                Update Configuration
                                {showConfig ? <ChevronUp className="w-3 h-3 sm:w-4 sm:h-4" /> : <ChevronDown className="w-3 h-3 sm:w-4 sm:h-4" />}
                            </button>
                        </div>
                    )}

                    {/* Configuration content */}
                    <div className="pt-6 sm:pt-8 space-y-4 sm:space-y-6">
                        {(showConfig || !hasCredentials) && (
                            <div className={`space-y-4 sm:space-y-6 ${hasCredentials ? 'mt-4' : ''}`}>
                                {/* Video section - Responsive iframe container */}
                                {showVideo ? (
                                    <div className="relative">
                                        <div className={`relative ${!expandedVideo ? 'w-full' : 'w-full sm:w-64'} transition-all duration-300 ease-in-out`}>
                                            <div className="aspect-video bg-gray-100 rounded-lg overflow-hidden">
                                                <iframe
                                                    width="100%"
                                                    height="100%"
                                                    src="https://www.youtube.com/embed/qyhdKb8liEA"
                                                    title="YouTube video player"
                                                    style={{ border: 0 }}
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() => setExpandedVideo(!expandedVideo)}
                                                className="absolute bottom-2 right-2 bg-black/70 text-white p-1.5 sm:p-2 rounded-full hover:bg-black/90"
                                            >
                                                {expandedVideo ? <ChevronDown className="w-3 h-3 sm:w-4 sm:h-4" /> : <ChevronUp className="w-3 h-3 sm:w-4 sm:h-4" />}
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => setShowVideo(false)}
                                                className="absolute top-2 right-2 bg-black/70 text-white p-1.5 sm:p-2 rounded-full hover:bg-black/90"
                                            >
                                                <X className="w-3 h-3 sm:w-4 sm:h-4" />
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <button
                                        type="button"
                                        onClick={() => setShowVideo(true)}
                                        className="flex items-center gap-2 text-blue-600 hover:text-blue-700 text-sm sm:text-base"
                                    >
                                        <Info className="w-4 h-4 sm:w-5 sm:h-5" />
                                        Watch Video to learn how to configure your credentials
                                    </button>
                                )}

                                {/* Form inputs - Added responsive padding and text sizes */}
                                <FormInput
                                    id="accountSid"
                                    name="accountSid"
                                    label="Account SID"
                                    value={credentials.accountSid}
                                    onChange={handleChange}
                                    placeholder="Enter Account SID"
                                    className="text-sm sm:text-base"
                                />

                                <FormInput
                                    id="authToken"
                                    name="authToken"
                                    label="Auth Token"
                                    type="password"
                                    value={credentials.authToken}
                                    onChange={handleChange}
                                    placeholder="Enter Auth Token"
                                    className="text-sm sm:text-base"
                                />

                                <FormInput
                                    id="applicationSid"
                                    name="applicationSid"
                                    label="Application SID"
                                    value={credentials.applicationSid}
                                    onChange={handleChange}
                                    placeholder="Enter Application SID"
                                    className="text-sm sm:text-base"
                                />

                                <FormInput
                                    id="apiKey"
                                    name="apiKey"
                                    label="API Key"
                                    type="password"
                                    value={credentials.apiKey}
                                    onChange={handleChange}
                                    placeholder="Enter API Key"
                                    className="text-sm sm:text-base"
                                />

                                <FormInput
                                    id="apiSecret"
                                    name="apiSecret"
                                    label="API Secret"
                                    type="password"
                                    value={credentials.apiSecret}
                                    onChange={handleChange}
                                    placeholder="Enter API Secret"
                                    className="text-sm sm:text-base"
                                />

                                <ActionButton
                                    variant="primary"
                                    className="w-full py-2.5 sm:py-3 text-sm sm:text-base"
                                >
                                    {!hasCredentials ? 'Save Configuration' : 'Update Configuration'}
                                </ActionButton>
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Credentials;
