import React from 'react';
import { useToastStore } from './store';
import { Toast } from './toastvar';

export const ToastContainer: React.FC = () => {
    const { toasts, removeToast } = useToastStore();

    return (
        <div className="fixed top-0 left-0 right-0 md:left-auto md:top-10 md:right-20 flex flex-col gap-4 z-50">
            {toasts.map((toast) => (
            <Toast
                key={toast.id}
                show={true}
                message={toast.message}
                variant={toast.variant}
                duration={toast.duration}
                customTitle={toast.customTitle}
                onClose={() => removeToast(toast.id)}
            />
            ))}
        </div>
    );
};