import Credentials from '@/components/credentials';
import React from 'react';

const Settings: React.FC = () => {

    return (
        <div className="max-w-2xl mx-auto p-2 pt-[3vh]">
            <Credentials />
        </div>
    );
};

export default Settings;