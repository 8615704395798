import React from 'react';
import { BaseButton, BaseButtonProps } from './base';

interface ActionButtonProps extends BaseButtonProps {
  variant?: 'primary' | 'secondary' | 'link' | 'bordered';
  icon?: React.ReactNode;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  variant = 'primary',
  icon,
  children,
  disabled,
  className = '',
  ...props
}) => {

  let variantClasses = "";

  if (disabled) {
    variantClasses = "bg-gray-300 text-gray-500 cursor-not-allowed transform-none shadow-none";
  } else {
    switch (variant) {
      case 'primary':
        variantClasses = "bg-gradient-to-r from-green-500 to-emerald-500 hover:from-green-600 hover:to-emerald-600 text-white shadow-lg hover:shadow-xl";
        break;
      case 'secondary':
        variantClasses = "bg-gray-200 hover:bg-gray-300 active:bg-gray-400 text-gray-600";
        break;
      case 'link':
        variantClasses = "bg-transparent text-blue-500 hover:underline";
        break;
      case 'bordered':
        variantClasses = "bg-transparent border border-gray-300 text-gray-600 hover:bg-gray-100";
        break;
      default:
        variantClasses = "bg-gray-200 text-gray-600";
    }
  }

  return (
    <BaseButton
      className={`transform hover:scale-[1.02] active:scale-[0.98] rounded-full bg-gray-200 hover:bg-gray-300 flex items-center justify-center flex gap-2 ${variantClasses} ${className}`}
      disabled={disabled}
      {...props}
    >
      {icon && (
        <span className="transition-transform duration-200 group-hover:scale-110">
          {icon}
        </span>
      )}
      {children}
    </BaseButton>
  );
};


export default ActionButton;