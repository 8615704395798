import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Phone,
    Settings,
    UserCircle,
    LogOut,
    CreditCard,
    Book,
    User
} from 'lucide-react';
import useContactStore from '../contacts/store';

// Tooltip Component (unchanged)
interface TooltipProps {
    text: string;
    show: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({ text, show }) => {
    if (!show) return null;

    return (
        <div className="absolute left-16 top-1/2 -translate-y-1/2 bg-gray-900 text-white text-sm px-3 py-2 rounded-lg whitespace-nowrap z-50">
            {text}
        </div>
    );
};

// Desktop NavButton
interface DesktopNavButtonProps {
    icon: React.ComponentType<{ className?: string; strokeWidth?: number }>;
    label: string;
    isActive?: boolean;
    isCall?: boolean;
    onClick?: () => void;
}

const DesktopNavButton: React.FC<DesktopNavButtonProps> = ({
    icon: Icon,
    label,
    isActive = false,
    isCall = false,
    onClick
}) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className="relative group">
            <button
                className={`
                    cursor-pointer
                    relative w-10 h-10 rounded-lg flex items-center justify-center
                    transition-all duration-200 
                    ${isCall ? 'bg-blue-500 text-white hover:bg-blue-600' :
                        isActive ? 'bg-gray-100 text-gray-900' :
                            'text-black hover:bg-gray-50 hover:text-gray-900'}
                `}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                onClick={onClick}
                aria-label={label}
            >
                <Icon className="w-5 h-5" strokeWidth={1.5} />
            </button>
            <Tooltip text={label} show={showTooltip} />
        </div>
    );
};

// Mobile NavButton
interface MobileNavButtonProps {
    icon: React.ComponentType<{ className?: string }>;
    label: string;
    isActive: boolean;
    onClick: () => void;
    badgeCount?: number;
}

const MobileNavButton: React.FC<MobileNavButtonProps> = ({
    icon: Icon,
    label,
    isActive,
    onClick,
    badgeCount
}) => (
    <button
        onClick={onClick}
        className="flex flex-col items-center justify-center px-3"
        aria-label={label}
    >
        <div className="relative">
            <Icon className={`w-6 h-6 ${isActive ? 'text-purple-500' : 'text-gray-400'}`} />
            {badgeCount !== undefined && badgeCount > 0 && (
                <div className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                    {badgeCount}
                </div>
            )}
        </div>
        <span className={`text-xs mt-1 ${isActive ? 'text-purple-500' : 'text-gray-400'}`}>
            {label}
        </span>
    </button>
);

const Navbar: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const setSelectedContact = useContactStore(state => state.setSelectedContact);

    const handleStartCall = () => {
        navigate('/');
        setSelectedContact(null);
    };

    const handleAccountClick = () => {
        navigate('/me');
    };

    const handleSettings = () => {
        navigate('/settings');
    };

    const handleContacts = () => {
        navigate('/contacts');
    };

    return (
        <>
            {/* Desktop Navbar */}
            <nav className="hidden md:flex h-screen bg-white fixed">
                <div className="w-16 border-r border-gray-100 flex flex-col items-center py-8">
                    {/* Logo */}
                    <div className="mb-8">
                        <div className="w-10 h-10 rounded-lg bg-gray-900 flex items-center justify-center">
                            <span className="text-white text-lg font-medium">L</span>
                        </div>
                    </div>

                    {/* Main Navigation */}
                    <div className="space-y-4">
                        <DesktopNavButton
                            icon={Phone}
                            label="Start call"
                            isCall
                            isActive
                            onClick={handleStartCall}
                        />
                        <DesktopNavButton
                            icon={Settings}
                            label="Settings"
                            onClick={handleSettings}
                        />
                        <DesktopNavButton
                            icon={UserCircle}
                            label="Account"
                            onClick={handleAccountClick}
                        />
                    </div>

                    {/* Spacing */}
                    <div className="flex-grow" />

                    {/* Bottom Navigation */}
                    <div className="space-y-4">
                        <DesktopNavButton
                            icon={CreditCard}
                            label="Billing"
                        />
                        <DesktopNavButton
                            icon={LogOut}
                            label="Logout"
                        />
                    </div>
                </div>
            </nav>

            {/* Mobile Navbar */}
            <nav className="md:hidden fixed bottom-0 left-0 right-0 bg-[#2c2c2e] border-t border-gray-800 z-50">
                <div className="h-16 flex items-center justify-around px-2">
                    <MobileNavButton
                        icon={Book}
                        label="Contacts"
                        isActive={location.pathname === '/contacts'}
                        onClick={handleContacts}
                    />
                    <MobileNavButton
                        icon={Settings}
                        label="Settings"
                        isActive={location.pathname === '/settings'}
                        onClick={handleSettings}
                    />
                    <MobileNavButton
                        icon={User}
                        label="Profile"
                        isActive={location.pathname === '/me'}
                        onClick={handleAccountClick}
                    />
                </div>
                {/* iPhone Safe Area */}
                <div className="h-[env(safe-area-inset-bottom)] bg-[#2c2c2e]" />
            </nav>
        </>
    );
};

export default Navbar;