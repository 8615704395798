import { doc, getDoc, setDoc, collection, query, orderBy, limit, getDocs, addDoc } from 'firebase/firestore';
import { create } from 'zustand';
import { db, functions } from '../../config/fconfig';
import useAuthStore from '../auth/store';
import { useToastStore } from '../toast/store';
import { httpsCallable } from 'firebase/functions';
import useCallStore from '@/config/twilio';

interface Credentials {
    id?: string;
    name: string;
    countryCode: string;
    phoneNumber: string;
    isActive: boolean;
    accountSid: string;
    authToken: string;
    applicationSid: string;
    apiKey: string;
    apiSecret: string;
    type: string;
    provider: string;
    updatedAt: string;
    configuration?: string;
}

interface CredentialsState {
    credentials: Credentials;
    hasCredentials: boolean; // Only true when fetched from Firestore or newly created
    showConfig: boolean;
    expandedVideo: boolean;
    showVideo: boolean;
    setCredentials: (credentials: Partial<Credentials>) => void;
    setShowConfig: (show: boolean) => void;
    setExpandedVideo: (expanded: boolean) => void;
    setShowVideo: (show: boolean) => void;
    saveToFirestore: () => Promise<void>;
    loadFromFirestore: (phoneNumber?: string) => Promise<void>;
    loadLatestAsset: (fetchToken?: boolean) => Promise<void>;
    callUserToken: ({ force }: { force?: boolean }) => Promise<void>;
}

interface CallUserTokenParams {
    orgId: string;
    assetId: string;
    force: boolean;
}

interface CallUserTokenResponse {
    token: string;
    isReused: boolean;
}

interface AssetData {
    id: string;
    name: string;
    countryCode: string;
    phoneNumber: string;
    isActive: boolean;
    updatedAt: string;
    configuration: string;
}

interface ConfigurationData {
    accountSid: string;
    apiKey: string;
    apiSecret: string;
    applicationSid: string;
    authToken: string;
    type: string;
    provider: string;
}

const DEFAULT_CREDENTIALS: Credentials = {
    name: '',
    countryCode: '+1',
    phoneNumber: '',
    isActive: true,
    accountSid: '',
    authToken: '',
    applicationSid: '',
    apiKey: '',
    apiSecret: '',
    type: 'CUSTOM',
    provider: 'TWILIO',
    updatedAt: new Date().toISOString()
};

const useCredentialsStore = create<CredentialsState>()((set, get) => ({
    credentials: DEFAULT_CREDENTIALS,
    hasCredentials: false,
    showConfig: false,
    expandedVideo: false,
    showVideo: false,
    configuration: null,

    setCredentials: (newCredentials) =>
        set((state) => ({
            credentials: { ...state.credentials, ...newCredentials },
            // Don't change hasCredentials here - it should only change when saving to or loading from Firestore
            hasCredentials: state.hasCredentials
        })),

    setShowConfig: (show) => set({ showConfig: show }),
    setExpandedVideo: (expanded) => set({ expandedVideo: expanded }),
    setShowVideo: (show) => set({ showVideo: show }),

    saveToFirestore: async () => {
        const { credentials } = get();
        if (!credentials.phoneNumber) {
            throw new Error('Phone number is required');
        }

        useToastStore.getState().addToast({
            message: 'Saving credentials...',
            variant: 'info',
        });

        const assetRef = doc(
            db,
            'ORG',
            useAuthStore.getState().getOrgId(),
            'ASSET',
            `${credentials.countryCode.replace("+", "")}${credentials.phoneNumber}`
        );

        const credentialRef = collection(
            db,
            'ORG',
            useAuthStore.getState().getOrgId(),
            'CONFIGURATION'
        );

        const updatedAsset = {
            name: credentials.name,
            countryCode: credentials.countryCode,
            phoneNumber: credentials.phoneNumber,
            isActive: credentials.isActive,
            updatedAt: new Date().toISOString(),
        };

        const updatedConfiguration = {
            accountSid: credentials.accountSid,
            apiKey: credentials.apiKey,
            apiSecret: credentials.apiSecret,
            applicationSid: credentials.applicationSid,
            authToken: credentials.authToken,
            type: DEFAULT_CREDENTIALS.type,
            provider: DEFAULT_CREDENTIALS.provider,
        }

        if (!credentials.configuration) {
            const config = await addDoc(credentialRef, updatedConfiguration);
            credentials.configuration = config.id;
            set({
                credentials: { ...credentials, configuration: config.id },
                hasCredentials: true
            });
        } else {
            await setDoc(
                doc(
                    db,
                    'ORG',
                    useAuthStore.getState().getOrgId(),
                    'CONFIGURATION',
                    credentials.configuration
                ), updatedConfiguration, { merge: true });
        }

        console.log({ ...updatedAsset, configuration: credentials.configuration })

        await setDoc(assetRef, { ...updatedAsset, configuration: credentials.configuration }, { merge: true });

        useToastStore.getState().addToast({
            message: 'Credentials saved successfully',
            variant: 'success',
        });

        set({
            credentials: { ...updatedConfiguration, ...updatedAsset },
            hasCredentials: true // Set to true after successful save
        });
    },

    loadFromFirestore: async (phoneNumber?: string) => {
        if (!phoneNumber) {
            return get().loadLatestAsset();
        }

        try {
            const assetRef = doc(
                db,
                'ORG',
                useAuthStore.getState().getOrgId(),
                'ASSET',
                phoneNumber
            );

            const assetSnap = await getDoc(assetRef);

            if (assetSnap.exists()) {
                const assetData = assetSnap.data() as AssetData;

                const configRef = doc(
                    db,
                    'ORG',
                    useAuthStore.getState().getOrgId(),
                    'CONFIGURATION',
                    assetData.configuration
                );

                const configSnap = await getDoc(configRef);

                if (configSnap.exists()) {
                    const configData = configSnap.data() as ConfigurationData;

                    const mergedData: Credentials = {
                        ...assetData,
                        ...configData,
                    };

                    set({
                        credentials: mergedData,
                        hasCredentials: true // Set to true only when successfully loaded from Firestore
                    });
                } else {
                    throw new Error('Configuration not found');
                }
            } else {
                set({
                    credentials: { ...DEFAULT_CREDENTIALS, phoneNumber },
                    hasCredentials: false // No existing credentials found
                });
            }
        } catch (error) {
            useToastStore.getState().addToast({
                message: `Failed to load credentials: ${(error as Error).message}`,
                variant: 'error',
            });
            set({
                credentials: { ...DEFAULT_CREDENTIALS, phoneNumber },
                hasCredentials: false
            });
        }
    },

    loadLatestAsset: async (fetchToken = true) => {
        const orgId = useAuthStore.getState().getOrgId();

        try {
            const assetsRef = collection(db, 'ORG', orgId, 'ASSET');
            const q = query(
                assetsRef,
                orderBy('updatedAt', 'desc'),
                limit(1)
            );

            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const assetData = querySnapshot.docs[0].data() as AssetData;

                const configRef = doc(
                    db,
                    'ORG',
                    orgId,
                    'CONFIGURATION',
                    assetData.configuration
                );

                const configSnap = await getDoc(configRef);

                if (configSnap.exists()) {
                    const configData = configSnap.data() as ConfigurationData;

                    const mergedData: Credentials = {
                        ...assetData,
                        ...configData,
                    };

                    set({
                        credentials: mergedData,
                        hasCredentials: true // Set to true only when successfully loaded from Firestore
                    });
                    if (fetchToken) {
                        get().callUserToken({});
                    }
                } else {
                    throw new Error('Configuration not found for latest asset');
                }
            } else {
                set({
                    credentials: DEFAULT_CREDENTIALS,
                    hasCredentials: false // No existing credentials found
                });
            }
        } catch (error) {
            useToastStore.getState().addToast({
                message: `Failed to load latest asset: ${(error as Error).message}`,
                variant: 'error',
            });
            set({
                credentials: DEFAULT_CREDENTIALS,
                hasCredentials: false
            });
        }
    },

    callUserToken: async ({ force = false }) => {
        try {
            useToastStore.getState().addToast({
                message: `Getting ${get().credentials.countryCode} ${get().credentials.phoneNumber} ready!`,
                variant: 'info',
            });

            const callUserTokenFunction = httpsCallable<CallUserTokenParams, CallUserTokenResponse>(
                functions,
                'callUserToken'
            )({ orgId: useAuthStore.getState().getOrgId(), assetId: `${get().credentials.countryCode}${get().credentials.phoneNumber}`.replace(/\+/g, ""), force });

            const { token } = (await callUserTokenFunction).data;

            useCallStore.getState().initializeDevice(token);

            set((state) => ({
                credentials: { ...state.credentials, assetToken: token },
                hasCredentials: state.hasCredentials // Maintain existing hasCredentials state
            }));
        } catch (error) {
            useToastStore.getState().addToast({
                message: `Failed to update user token: ${(error as Error).message}`,
                variant: 'error',
            });
        }
    }
}));

export default useCredentialsStore;