import { User } from 'lucide-react';

const colorMap: { [key: string]: { bg: string; text: string } } = {
    'bg-blue-500': { bg: 'bg-blue-100', text: 'text-blue-500' },
    'bg-green-500': { bg: 'bg-green-100', text: 'text-green-500' },
    'bg-yellow-500': { bg: 'bg-yellow-100', text: 'text-yellow-500' },
    'bg-purple-500': { bg: 'bg-purple-100', text: 'text-purple-500' },
    'bg-pink-500': { bg: 'bg-pink-100', text: 'text-pink-500' },
    'bg-indigo-500': { bg: 'bg-indigo-100', text: 'text-indigo-500' },
    'bg-red-500': { bg: 'bg-red-100', text: 'text-red-500' },
    'bg-orange-500': { bg: 'bg-orange-100', text: 'text-orange-500' },
};

const getColorFromInput = (input: string): string => {
    const alphanumericInput = input.replace(/[^a-zA-Z0-9]/g, '');
    const sum = alphanumericInput.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return Object.keys(colorMap)[sum % Object.keys(colorMap).length];
};

interface ContactAvatarProps {
    input: string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
}

const ContactAvatar = ({ input, size = 'md', className = '', onClick }: ContactAvatarProps & { className?: string; onClick?: () => void }) => {
    const avatarColor: keyof typeof colorMap = getColorFromInput(input);
    const { bg, text } = colorMap[avatarColor];

    const sizeClasses = {
        xs: 'w-10 h-10',
        sm: 'w-12 h-12',
        md: 'w-16 h-16',
        lg: 'w-20 h-20',
        xl: 'w-24 h-24',
        '2xl': 'w-28 h-28'
    };

    const iconSizes = {
        xs: 'w-6 h-6',
        sm: 'w-8 h-8',
        md: 'w-10 h-10',
        lg: 'w-12 h-12',
        xl: 'w-14 h-14',
        '2xl': 'w-16 h-16'
    };

    return (
        <div
            className={`rounded-full ${bg} ${sizeClasses[size]} flex items-center justify-center ${className}`}
            onClick={onClick}
        >
            <User className={`${iconSizes[size]} ${text}`} />
        </div>
    );
};

export default ContactAvatar;
