import React, { useState } from 'react';
import { Phone } from 'lucide-react';
import { PhoneDialerProps, Country } from '../types';
import { COUNTRIES } from '../constants';
import CountrySelector from '../blocks/select/countries';
import PhoneInput from './input';
import DialPad from './dialpad';
import ActionButton from '../blocks/button/action';
import { formatPhoneNumber } from './utils';
import useCredentialsStore from '../credentials/store';
import useActivityStore from '../activity/store';

const PhoneDialer: React.FC<PhoneDialerProps> = () => {
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [selectedCountry, setSelectedCountry] = useState<Country>(COUNTRIES[0]);
    const [showDialPad] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const createCallActivity = useActivityStore(state => state.createCallActivity);
    const fromPhoneNumber = useCredentialsStore(state => state.credentials.phoneNumber);

    const handleNumberClick = (number: string): void => {
        if (phoneNumber.length < 15) {
            setPhoneNumber(prev => prev + number);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value.replace(/\D/g, '');
        if (value.length <= 15) {
            setPhoneNumber(value);
        }
    };

    const handleClear = (): void => {
        setPhoneNumber('');
    };

    const handleCall = async (): Promise<void> => {
        if (phoneNumber.length >= 10) {
            setIsLoading(true);
            const fullNumber = selectedCountry.code + phoneNumber;

            try {
                await createCallActivity({
                    phoneNumber: fullNumber,
                    direction: 'OUT',
                    status: 'ongoing',
                    note: 'Outbound call initiated',
                    duration: 0
                });
            } catch (error) {
                console.error('Error initiating call:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="w-full max-w-2xl mx-auto">
            <div className="bg-gray-900 rounded-lg p-4 md:p-6 w-full">
                <div className="mb-4 md:mb-6">
                    <h1 className="text-2xl md:text-3xl font-semibold text-white text-left mb-2">
                        Start a call
                    </h1>
                    <div className=" sm:items-center text-sm md:text-base text-gray-400">
                        {fromPhoneNumber ? (
                            <>
                                <span>Make the call from </span>
                                <span className="text-white">
                                    {formatPhoneNumber(fromPhoneNumber)}
                                </span>
                            </>
                        ) : (
                            <span className="text-red-500">
                                No phone to use. Set up a phone first
                            </span>
                        )}
                    </div>
                </div>

                <div className="relative mb-4">
                    <div className="flex gap-2 flex-row sm:gap-3">
                        <div className={` ${phoneNumber ? 'sm:block' : 'sm:hidden'}`}>
                            <CountrySelector
                                selectedCountry={selectedCountry}
                                onSelectCountry={setSelectedCountry}
                            />
                        </div>
                        <div className='flex-1'>
                            <PhoneInput
                                phoneNumber={phoneNumber}
                                onPhoneNumberChange={handleInputChange}
                                onClear={handleClear}
                                placeholder="Phone number"
                            />
                        </div>
                    </div>
                </div>

                {phoneNumber.length >= 10 && (
                    <div className="flex justify-center sm:justify-between gap-4 mt-4">
                        <ActionButton
                            variant="primary"
                            icon={<Phone size={20} className="md:w-6 md:h-6" />}
                            onClick={handleCall}
                            disabled={phoneNumber.length < 10 || isLoading || !fromPhoneNumber}
                            className="w-full sm:w-2/3 py-2 md:py-3 text-sm md:text-base bg-blue-600 hover:bg-blue-700 disabled:bg-blue-800 disabled:cursor-not-allowed"
                        >
                            {isLoading ? 'Initiating...' : 'Call'}
                        </ActionButton>
                    </div>
                )}

                <div className={`flex-auto transition-all duration-300 ease-in-out ${showDialPad ? 'flex mt-4' : 'hidden'}`}>
                    <DialPad onNumberClick={handleNumberClick} />
                </div>
            </div>
        </div>
    );
};

export default PhoneDialer;