import React from 'react';
import { X } from 'lucide-react';
import { PhoneInputProps } from '../types';
import { formatPhoneNumber } from './utils';
import FormInput from '../blocks/form/input';


const PhoneInput: React.FC<PhoneInputProps> = ({
  phoneNumber,
  placeholder,
  onPhoneNumberChange,
  onClear,
}) => (
  <div className="flex-1 relative bg-white rounded-lg">
    <FormInput
      id="phoneNumber"
      name="phoneNumber"
      type='number'
      value={formatPhoneNumber(phoneNumber)}
      onChange={onPhoneNumberChange}
      placeholder={placeholder}
      className="w-full h-full px-4 py-4 text-xl font-mono rounded-lg"
    />
    {phoneNumber && (
      <button
        onClick={onClear}
        className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
      >
        <X size={20} />
      </button>
    )}
  </div>
);

export default PhoneInput;