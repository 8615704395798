import React, { useEffect, useCallback, useState } from 'react';
import { Pencil, Phone, X } from 'lucide-react';
import ContactAvatar from '../blocks/avatar';
import useActivityStore from '../activity/store';
import { formatDistanceToNow } from 'date-fns';
import CallTimeline from '../activity/timeline';
import ContactDetailsCard from '../contact';
import useContactStore, { IContactDetails } from './store';
import { Timestamp } from 'firebase/firestore';
import { useToastStore } from '@/components/toast/store';

const ContactUI: React.FC = () => {
    const [showProfile, setShowProfile] = useState(false);
    const selectedContact = useContactStore(state => state.selectedContact) as IContactDetails | null;
    const fetchActivitiesByContact = useActivityStore(state => state.fetchActivitiesByContact);
    const createCallActivity = useActivityStore(state => state.createCallActivity);
    const addToast = useToastStore(state => state.addToast);

    useEffect(() => {
        if (selectedContact) {
            fetchActivitiesByContact();
        }
    }, [selectedContact, fetchActivitiesByContact]);

    const handleCallInitiation = useCallback(async () => {
        if (!selectedContact) return;

        await createCallActivity({
            phoneNumber: selectedContact.phone,
            direction: 'OUT',
            status: 'ongoing',
            note: '',
            duration: 0
        });
    }, [selectedContact, createCallActivity, addToast]);

    if (!selectedContact) {
        return null;
    }

    const lastCallText = selectedContact.lastActivity ? `Last call ${formatDistanceToNow(
        selectedContact.lastActivity.startTime instanceof Date
            ? selectedContact.lastActivity.startTime
            : (selectedContact.lastActivity.startTime as Timestamp).toDate(),
        {
            addSuffix: true,
            includeSeconds: true
        }
    )}` : 'No recent calls';

    return (
        <div className="w-full pt-[4vh] basis-full px-4 md:px-0 h-screen flex flex-col">
            {/* Contact Card */}
            <div className="p-4 bg-white rounded-3xl shadow-lg">
                <div className="flex flex-col sm:flex-row items-center gap-4">
                    {/* Avatar and Info */}
                    <div className="flex flex-col sm:flex-row items-center sm:items-start gap-4 w-full">
                        <ContactAvatar input={selectedContact.name || selectedContact.phone} size="lg" />
                        <div className="flex-1 text-center sm:text-left">
                            <div className="text-blue-800 text-xl md:text-2xl font-semibold break-words">
                                {selectedContact.name || selectedContact.phone}
                            </div>
                            <div className="text-gray-500 text-sm md:text-base">
                                {lastCallText}
                            </div>
                            <div className="text-xs md:text-sm text-gray-400">
                                {selectedContact.lastActivity?.status === 'completed' && `Duration: ${selectedContact.lastActivity?.duration}s`}
                                {selectedContact.lastActivity?.status === 'missed' && 'Missed Call'}
                                {selectedContact.lastActivity?.status === 'ongoing' && 'Ongoing Call'}
                                {selectedContact.lastActivity?.status === 'answered' && 'Call Answered'}
                            </div>
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex justify-center sm:justify-end space-x-3">
                        <button
                            onClick={handleCallInitiation}
                            className="bg-green-500 p-3 md:p-4 rounded-full hover:bg-green-600 transition-colors cursor-pointer focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2"
                            aria-label="Initiate call"
                        >
                            <Phone className="w-5 h-5 md:w-6 md:h-6 text-white" />
                        </button>
                        
                        {/* Profile Toggle Button - Only visible on mobile */}
                        <button
                            onClick={() => setShowProfile(!showProfile)}
                            className="lg:hidden bg-blue-500 p-3 md:p-4 rounded-full hover:bg-blue-600 transition-colors cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
                            aria-label={showProfile ? "Close profile view" : "Toggle profile view"}
                        >
                            {showProfile ? (
                                <X className="w-5 h-5 md:w-6 md:h-6 text-white" />
                            ) : (
                                <Pencil className="w-5 h-5 md:w-6 md:h-6 text-white" />
                            )}
                        </button>
                    </div>
                </div>
            </div>

            {/* Timeline and Details Section */}
            <div className="flex flex-col lg:flex-row gap-6 mt-6 md:mt-8 flex-1 overflow-hidden">
                {/* Timeline - Hidden on mobile when profile is shown */}
                <div className={`w-full overflow-y-auto no-scrollbar lg:w-4/6 ${showProfile ? 'hidden lg:block' : 'block'} h-full`}>
                    <CallTimeline />
                </div>
                
                {/* Vertical Divider - Only visible on larger screens */}
                <div className="hidden lg:block border-l border-gray-300"></div>
                
                {/* Profile Section - Hidden on mobile when timeline is shown */}
                <div className={`w-full lg:w-2/6 ${!showProfile ? 'hidden lg:block' : 'block'}`}>
                    <ContactDetailsCard userId={selectedContact.id} />
                </div>
            </div>
        </div>
    );
};

export default ContactUI;